h1,h2,h3,h4,h5 {
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
}
h1 {
  font-family: $boldFont;
}
h2 {
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.08;
  letter-spacing: normal;
  text-align: left;
  color: var(--black);
  padding: 0 0 30px 0;
}
h4 {
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #4c4951;
  span {
    font-weight: bold;
  }
}
h5 {
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: left;
  color: #000000;
}
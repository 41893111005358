.item__explore {
  background-image: url("./assets/images/explore.svg");
}
.item__explore.active {
  background-image: url("./assets/images/exploreActive.svg");
}
.item__colleagues {
  background-image: url("./assets/images/colleaguesInactive.svg");
}
.item__colleagues.active {
  background-image: url("./assets/images/colleaguesActive.svg");
}
.item__dashboard.active {
  background-image: url("./assets/images/dashboardActive.svg");
}
.item__dashboard {
  background-image: url("./assets/images/dashboardInactive.svg");
}
.item__engagements {
  background-image: url("./assets/images/engagement.svg");
}
.item__payments {
  background-image: url("./assets/images/payments.svg");
}
.item__my {
  background-image: url("./assets/images/resume.svg");
}
.item__support {
  background-image: url("./assets/images/support.svg");
}
.header {
  background-image: url("./assets/images/searchBg.png");
}
.header--search {
  background-image: url("./assets/images/searchBg.svg");
}
.header--welcome {
  background-image: url("./assets/images/searchBg.svg");
}
.header--welcome.forgotPass {
  background-image: url("./assets/images/forgorPass.png");
}
.carousel-control-prev-icon {
  background-image: url("./assets/images/arrowLeft.svg") !important;
}
.carousel-control-next-icon {
  background-image: url("./assets/images/arrowRight.svg") !important;
}
.viewAll {
  background-image: url("./assets/images/viewMore.png");
}
.peopleViewAll {
  background-image: url("./assets/images/peopleViewAll.png");
}
.phoneNo {
  background-image: url("./assets/images/mobile.png");
}
.location {
  background-image: url("./assets/images/location.svg");
}
.locationWhite {
  background-image: url("./assets/images/locationWhite.svg");
}
.expCard__location {
  background-image: url("./assets/images/location.svg");
}
.locationSearch {
  background-image: url("./assets/images/locationSearch.svg");
}
.distance {
  background-image: url("./assets/images/distance.svg");
}
.myProfile {
  background-image: url("./assets/images/name.svg");
}
.logout {
  background-image: url("./assets/images/logout.svg");
}
.email {
  background-image: url("./assets/images/email.png");
}
.name, .travelTo {
  background-image: url("./assets/images/name.svg");
}
.password {
  background-image: url("./assets/images/visibility.png");
}
.error {
  background-image: url("./assets/images/error.png");
}
.location {
  background-image: url("./assets/images/location.svg");
}
.online {
  background-image: url("./assets/images/online.svg");
}
.travel {
  background-image: url("./assets/images/travel.svg");
}
.likeIconBg {
  background-image: url("./assets/images/engagements.svg");
}
.button--back {
  background-image: url("./assets/images/backarrow.svg");
}
.popup__wrap {
  background-image: url("./assets/images/wavepattern.png");
}
.userDetailPage, .forgotPass__wrapper {
  background-image: url("./assets/images/wavePatternMain.png");
}
.bgLines {
  background-image: url("./assets/images/bgLines.png");
}
.sort {
  background-image: url("./assets/images/sort.svg");
}
.beginner {
  background-image: url("./assets/images/beginner.png");
}
.intermediate {
  background-image: url("./assets/images/intermediate.png");
}
.advanced {
  background-image: url("./assets/images/advanced.png");
}
.skillSearch .select__control {
  background-image: url("./assets/images/search.png");
}
.bannerBlueWrap {
  background-image: url("./assets/images/profileBgcopy.svg");
}
.expDetail__header {
  background-image: url("./assets/images/exp.svg");
}
.name__verified {
  background-image: url("./assets/images/tick.svg");
}
.engagements {
  background-image: url("./assets/images/like.svg");
}
.press {
  background-image: url("./assets/images/quotes.png");
}
// .expDetail__header {
//   background-image: url("./assets/images/profileHeaderBg.png");
// }
.skillbook__engagements  {
  background-image: url("./assets/images/engagements.svg");
}
.tick {
  background-image: url("./assets/images/tickWhite.png");
}
.url {
  background-image: url("./assets/images/urlSmall.svg");
}
.creator {
  background-image: url("./assets/images/name.svg");
}
.work {
  background-image: url("./assets/images/work.svg");
}
.audioCard__text  {
  background-image: url("./assets/images/link.svg");
}
.expCard__wrap1 {
  background-image: url("./assets/images/rightArrow.svg");
}
.expCard__date {
  background-image: url("./assets/images/calender.svg");
}
.left__navigation.collapse {
  background-image: url("./assets/images/sidebarBg.png");
}
.searchDetailHeader {
  background-image: url("./assets/images/search-resultBg.png");
}
.highlight1 {
  background-image: url("./assets/images/highlight1.png");
}
.highlight2 {
  background-image: url("./assets/images/highlight2.png");
}
.highlight3 {
  background-image: url("./assets/images/highlight3.png");
}
.highlight4 {
  background-image: url("./assets/images/highlight4.png");
}
.highlight5 {
  background-image: url("./assets/images/highlight5.png");
}
.highlight6 {
  background-image: url("./assets/images/highlight6.png");
}
.bannerImage {
  background-image: url("./assets/images/cover.png");
}
.dropdown-toggle::after {
  background-image: url("./assets/images/arrowWhite.svg");
}
.plainHeader .dropdown-toggle::after {
  background-image: url("./assets/images/arrowBlack.svg");
}
.browseVideoDescription__title .date {
  background-image: url("./assets/images/calender.svg");
}
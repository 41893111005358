/* Reset */
// *,
// *::before,
// *::after {
// 	box-sizing: inherit;
// }
*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  outline: none;
}
html {
  box-sizing: border-box;
}

body {
  font-family: $baseFont !important;
  background-color: #f5f4f8;
  font-size: 16px;
  color: #200e32;
  margin: 0;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-decoration-skip-ink: auto;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-size-adjust: none;
  text-rendering: optimizeLegibility;
}

nav {
  ul,
  ol {
    padding: 0;
    margin: 0;
    list-style: none outside none;
  }
}

table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
}

img,
iframe,
video,
audio,
object {
  max-width: 100%;
}

img,
iframe {
  border: 0 none;
}

img {
  height: auto;
  display: inline-block;
  vertical-align: middle;
}
p {
  font-size: 12px;
font-weight: normal;
font-stretch: normal;
font-style: normal;
line-height: 1.67;
letter-spacing: normal;
color: #4c4951;
}
svg:not(:root) {
  overflow: hidden;
}
hr {
  border-top: 1px solid #e5e5ea;
}
figure {
  margin: 0;
}
.clear {
  clear: both;
}
.mar--t25 {
  margin-top: 25px;
}
.card {
  border-radius: 6px;
  box-shadow: 0 20px 35px -16px rgba(64, 57, 78, 0.15);
  background-color: var(--white);
  .card-img,
  .card-img-top {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
  }
  .card-title {
    margin-bottom: 5px;
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: var(--black-two);
    span {
      background-color: var(--color-fill-green);
      border-radius: 50%;
      width: 6px;
      height: 6px;
      padding: 0px 2px;
      line-height: 0;
      font-size: 6px;
      color: var(--color-fill-green);
    }
  }
  .card-text {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: normal;
    color: var(--charcoal-grey);
  }
}

.navbar-light .navbar-nav .nav-link:focus,
.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-link {
  color: #ffffff;
}
.carousel-control-next-icon,
.carousel-control-prev-icon {
  width: 27px !important;
  height: 27px !important;
}
.carousel-control-next,
.carousel-control-prev {
  top: -60px !important;
  bottom: auto !important;
  display: block !important;
  opacity: 1 !important;
  width: 30px !important;
}
.carousel-control-prev {
  right: 34px !important;
  left: auto !important;
}

.custom__choose {
  display: flex;
  justify-content: normal;
  align-items: center;
  input[type="radio"],
  input[type="checkbox"] {
    display: none;
  }
  input[type="radio"] + label,
  input[type="radio"]:not(checked) + label,
  input[type="checkbox"] + label,
  input[type="checkbox"]:not(checked) + label {
    padding: 7px 33px 7px 34px;
    border-radius: 4px;
    background-color: #ffffff;
    border: solid 1px #e5e5ea;
    text-align: center;
    font-size: 12px;
    margin: 0 10px 0 0;
    transition: 0.4s;
    cursor: pointer;
  }
  input[type="radio"]:checked + label,
  input[type="checkbox"]:checked + label {
    position: relative;
    background-image: linear-gradient(to top, #8259ff, #6217f6);
    color: #fff;
    transition: 0.4s;
  }
}
.day__Choose {
  input[type="radio"],
  input[type="checkbox"] {
    display: none;
  }
  input[type="radio"] + label,
  input[type="radio"]:not(checked) + label,
  input[type="checkbox"] + label,
  input[type="checkbox"]:not(checked) + label {
    padding: 2px 5px;
    border-radius: 4px;
    background-color: #ffffff;
    border: solid 1px #e8e6ef;
    text-align: center;
    font-size: 12px;
    margin: 0 5px 5px 0;
    transition: 0.4s;
    cursor: pointer;
    float: left;
    color: #4c4951;
    border-radius: 50%;
    width: 25px;
    height: 25px;
  }
  input[type="radio"]:checked + label,
  input[type="checkbox"]:checked + label {
    position: relative;
    background-color: $color5;
    color:$white;
    transition: 0.4s;
    border: solid 1px $color5;
  }
}
.customFilter__choose {
  input[type="radio"],
  input[type="checkbox"] {
    display: none;
  }
  input[type="radio"] + label,
  input[type="radio"]:not(checked) + label,
  input[type="checkbox"] + label,
  input[type="checkbox"]:not(checked) + label {
    padding: 0px 8px;
    border-radius: 4px;
    background-color: #ffffff;
    border: solid 1px #d9d9db;
    text-align: center;
    font-size: 12px;
    margin: 0 10px 10px 0;
    transition: 0.4s;
    cursor: pointer;
    float: left;
    color: #4c4951;
  }
  input[type="radio"]:checked + label,
  input[type="checkbox"]:checked + label {
    position: relative;
    background-color: #efeff7;
    color: #4c4951;
    transition: 0.4s;
    border: solid 1px #efeff7;
  }
}
.react-datepicker-wrapper {
  width: 100%;
  padding: 0 0 15px 0;
  input.react-datepicker-ignore-onclickoutside {
    border: 0 !important;
  }
  input {
    width: 100%;
    padding: 0;
    border: 0;
    outline: none;
  }
}

.checkbox__container {
	display: block;
    position: relative;
    padding-right: 20px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    user-select: none;
    float: right;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  input:checked ~ .checkmark {
    background-color: $color5;
  }
  input:checked ~ .checkmark:after {
    display: block;
  }
  .checkmark:after {
	left: 7px;
    top: 3px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  border: 1px solid #cacaca;
  border-radius: 2px;
}

.checkbox__container:hover input ~ .checkmark {
  background-color: #ccc;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

// .accordion {
//   margin-top: 40px;

//   div:has(> a.collapse) {
//     background-image: url(https://cdn0.iconfinder.com/data/icons/arrows-android-l-lollipop-icon-pack/24/expand2-256.png);
//   }
//   div:has(> a.collapse.show) {
//     background: url(https://cdn0.iconfinder.com/data/icons/entypo/91/arrow56-512.png) no-repeat calc(100% - 10px) center;
//     background-size: 20px;
//     cursor: pointer;
//     font-size: 18px;
//   }
//   .card {
//     border: none;
//     margin-bottom: 20px;
//     &-body {
//       padding-left: 0;
//       padding-right: 0;
//     }
//   }
// }

.margin--topBottom25 {
  margin: 25px 0;
}
.margin--top25 {
  margin: 25px 0 0 0;
}
.MuiSlider-thumb, .MuiSlider-track, .MuiSlider-rail, .MuiSlider-thumb span {
  background-color: $color5 !important;
}
.MuiIconButton-colorSecondary {
  color:  $color5 !important;
}
.MuiSvgIcon-root.MuiSelect-icon {
  display: none !important;
}
.MuiSelect-root {
  font-size: 0 !important;
}
.MuiSelect-select:focus {
  background-color: transparent !important;
}
.MuiInput-underline:after {
    transform: scaleX(0) !important;
    transition: none !important;
    border-bottom: none !important;
}
.MuiInput-underline:hover:not(.Mui-disabled):before, .MuiInput-underline:before {
  border-bottom: none !important;
}
.MuiInputLabel-shrink {
  transform: translate(0, 24px) scale(1) !important;
  transform-origin: unset !important;
}
.MuiFormLabel-root {
  color: #4e4b53 !important;
}
.MuiFormLabel-root.Mui-focused {
  color: #4e4b53 !important;
}
.MuiSelect-select {
  min-width: 47px !important;
}
.MuiInputLabel-formControl {
  padding: 0 0 0 25px !important;
  text-transform: uppercase;
  font-size: 14px !important;
}
.save {
  width: 373px;
}
.dropdown-toggle::after {
  width: 22px;
    height: 18px;
    border: none !important;
    background-repeat: no-repeat;
    background-position: 0 2px;
    position: absolute;
}

.skillSet__wrap > div > div > .skillbook {
  margin-right: 15px;
  margin-left: 15px;
}
.skillSet__wrap {
  margin: 0 -15px;
}
// .expDetail > div > div .expDetail__body {
//   margin-right: 15px;
// }
.experiance__cardWrp > div > div .expCard {
  margin-right: 15px;
  margin-left: 15px;
}
.experiance__cardWrp {
  margin: 0 -15px;
}
:root {
    --charcoal-grey: #4c4951;
    --purplish-blue: #6226f4;
    --black: #262626;
    --white: #ffffff;
    --black-two: #000000;
    --purplish-blue-two: #6317f6;
    --purplish-grey: #57525c;
    --periwinkle: #8054ff;
    --squash: #f6a210;
    --very-light-pink: #ffe6d8;
    --dusty-orange: #ee7b42;
    --purply-blue-50: rgba(92, 19, 237, 0.5);
    --color-fill-black-100: #200e32;
    --default-grey-color-text-icon-default: #706e6b;
    --flat-secondary-light-red: #fff1f3;
    --color-fill-black-40: rgba(32, 14, 50, 0.4);
    --flat-primary-light-grey: #f4f4fa;
    --color-fill-green: #00bec0;
    --flat-primary-purple: #5c66ec;
    --color-fill-bg-light-grey: #efeff7;
    --color-fill-purple-plain-purple: #6317f7;
  }


$color1: #580fe9;
$color2: #4c4951;
$color3:#f5f4f8;
$color4: #6226F4;
$color5: #00bec0;

$white: #ffffff;
$black: #000000;
.spinner__container {
  margin: 50px auto;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  .spinner {
    display: flex;
    align-self: flex-start;
    --size: 62px;
    width: var(--size);
    height: var(--size);
    border-radius: 50%;
    perspective: 800px;
    .spinner__inner {
      position: absolute;
      box-sizing: border-box;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      border: 3px solid transparent;
    }

    .spinner__inner--1 {
      left: 0;
      top: 0;
      animation: rotate-one 1s linear infinite;
      border-bottom: 3px solid $color1;
    }

    .spinner__inner--2 {
      right: 0;
      top: 0;
      animation: rotate-two 1s linear infinite;
      border-right: 3px solid $color1;
    }

    .spinner__inner--3 {
      right: 0;
      bottom: 0;
      animation: rotate-three 1s linear infinite;
      border-top: 3px solid $color1;
    }
  }
}

.header {
  .navbar-brand {
    max-width: 120px;
  }
  .nav-link {
    color: $white !important;
    font-family: $mediumFont;
    opacity: 0.8;
    padding: 7px 0 0 0 !important;
    margin-right: 25px;
  }
  .nav-link.active {
    opacity: 1;
    border-bottom: 2px solid $white;
  }
}
.notification {
  width: 25px;
  height: 25px;
  margin: 6px 25px 2px 0;
  object-fit: contain;
  cursor: pointer;
}
.greyNotify {
  display: none;
}
.profileImage {
  background-repeat: no-repeat;
  background-position: left;
  padding: 0;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-top: 4px;
}
.showPop {
  display: block;
}
.hidePop {
  display: none;
}
.popup__container {
  .tab__body {
    padding: 10px 0 5px 0;
  }
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  -webkit-backdrop-filter: blur(8px);
  -moz-backdrop-filter: blur(8px);
  -ms-backdrop-filter: blur(8px);
  -o-backdrop-filter: blur(8px);
  backdrop-filter: blur(8px);
  background-color: rgba(50, 84, 190, 0.2);
  z-index: 9999;
  .popup__head {
    background-color: #3254be;
    padding: 20px;
    h3 {
      color: #ffffff;
      margin: 0;
      float: left;
      width: calc(100% - 40px);
    }
  }
  .popup__content {
    padding: 20px;
  }
  .popup__buttonWrapper {
    float: right;
  }
  .popup__box {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    -webkit-animation: fadein 2s;
    -moz-animation: fadein 2s;
    -ms-animation: fadein 2s;
    -o-animation: fadein 2s;
    animation: fadein 2s;
    .popup__wrap {
      border-radius: 5px;
      background-color: $white;
      position: relative;
      width: 90%;
      max-width: 550px;
      background-repeat: no-repeat;
      background-position: right top;
      img {
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        // border-radius: 8px;
      }
      h2 {
        margin: 0;
        padding: 0 0 15px 0;
      }
      .button--transparent {
        margin-right: 20px;
      }
      .close__popup {
        color: #4c4951;
        cursor: pointer;
        float: right;
        position: absolute;
        top: 15px;
        right: -25px;
      }
    }
  }
}
.popup__logout.popup__container {
  .popup__wrap {
    background-image: none;
    border-radius: 5px;
    max-width: 463px;
    box-shadow: 1px 1px 20px 0 rgba(0, 0, 0, 0.15);
  }
  .popup__content {
    padding: 58px 41px;
    text-align: center;
  }
  p {
    font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  text-align: left;
  color: #262626;
  max-width: 312px;
  float: left;
  margin: 0;
  }
  .popup__buttonWrapper {
    float: none;
    margin-top: 29px;
  }
  .logout__img {
    float: right;
    max-width: 58px;
    margin-left: 10px;
  }
  .button--shadow {
    margin-right: 30px;
  }
  .button--primary {
    border: 0;
    padding: 10px 48px 10px 48px;
  border-radius: 6px;
  box-shadow: 0 10px 10px -6px rgba(92, 19, 237, 0.5);
  background-image: linear-gradient(to top, #8259ff, #6217f6);
  &:hover {
    opacity: 0.8;
    outline: none;
  }
  &:focus {
    outline: none;
  }
  }
  .close__popup {
    top: 20px !important;
    right: 20px !important;
}
}
.popup__container.popupSkill {
  .popup__wrap {
    background-image: none;
    border-radius: 5px;
  }
  .popup__head {
    background-color: $white;
    border-radius: 5px;
  }
  h3 {
    color: var(--black-two);
    font-size: 20px;
    font-weight: 500;
  }
  .popup__box .popup__wrap .close__popup {
    color: var(--charcoal-grey);
    top: 15px;
    right: 15px;
    font-size: 0;
  }
  .popup__content {
    padding: 0 20px 20px 20px;
  }
}
.popup__container.popup__custom {
  .popup__wrap {
    max-width: 940px;
    .close__popup {
      width: 20px;
      right: 15px;
    }
    img {
      float: left;
      width: 100%;
    }
    .popup__left {
      float: left;
      width: 40%;
      position: relative;
    }
    .askQuestion__left {
      position: absolute;
      left: 0;
      top: 0;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      display: none;
      display: flex;
      text-align: center;
      p {
        text-align: center;
        color: $white;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.57;
        letter-spacing: 0.51px;
        text-align: center;
      }
      button {
        margin-bottom: 30px;
      }
      .donHaveAcc {
        font-size: 12px;
        letter-spacing: 0;
        margin: 0 0 60px 0;
        span {
          font-weight: bold;
          text-decoration: underline;
          cursor: pointer;
        }
      }
      .askQuestion__leftBottom {
        position: absolute;
        bottom: 40px;
        left: 0;
        right: 0;
      }
      .store__wrap {
        width: 280px;
        margin: 0 auto;
        .appstore {
          width: 135px;
          float: left;
          margin-right: 10px;
          cursor: pointer;
        }
        .playstore {
          width: 135px;
          float: right;
          cursor: pointer;
        }
      }
    }
  }
  .popup__content {
    text-align: left;
    padding: 30px 75px !important;
    width: 60%;
    float: right;
  }
  label {
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.67;
    letter-spacing: normal;
    color: var(--charcoal-grey);
    width: 100%;
  }
}
.email__container {
  width: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  padding: 80px 0;
  h2 {
    padding: 0 0 10px 0;
  }
  p {
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.67;
    letter-spacing: normal;
    color: var(--charcoal-grey);
  }
  input {
    width: 50px;
    margin-right: 20px !important;
    float: left;
    padding: 10px 0;
    border: 1px solid #d8d8d8;
  }
  button {
    padding: 10px 40px;
    text-transform: uppercase;
  }
  .link__container {
    text-align: center;
    margin: 39px 0 0 0;
    .link {
      color: $color1;
      font-size: 12px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 2.17;
      letter-spacing: normal;
      color: var(--purplish-blue);
      cursor: pointer;
    }
  }
}
.checkSelected {
  border: 1px solid $color5 !important;
}
.inputIcon {
  background-repeat: no-repeat;
  background-position: 96% center;
}
.inputIcon--password {
  border: none;
  background: none;
}
.dropdown-menu {
  padding: 5px 15px;
}
.myProfile {
  background-position: left;
  background-repeat: no-repeat;
}
.logout {
  background-position: left;
  background-repeat: no-repeat;
}
.error {
  font-size: 10px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  text-align: left;
  color: #ec4d4d !important;
  margin-top: -10px;
  padding: 2px 0 0 13px;
  margin-bottom: 0;
  background-repeat: no-repeat;
  background-position: left center;
}
.error--resend {
  font-size: 10px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  text-align: center;
  margin-top: -10px;
  padding: 2px 0 0 0;
  margin-bottom: 0;
  color: #000 !important;
}
.popup__content {
  .form-group {
    position: relative;
    margin-bottom: 0px;
  }
  .error {
    position: absolute;
    bottom: -21px;
  }
}
.searchDetailHeader {
  .header__head {
    display: none;
  }
  .navItems {
    margin-top: 0 !important;
  }
  .search__container {
    width: 562px;
    margin-top: -42px;
  }
}

.header--search.plainHeader {
  padding: 30px 0;
  background-image: none;
  background-color: transparent;
  .navbar-light .navbar-nav .nav-link:focus,
  .navbar-light .navbar-nav .nav-link:hover,
  .navbar-light .navbar-nav .nav-link {
    color: #000000;
  }
  .navbar-nav {
    .button--transparent {
      color: #000000;
      border: 1px solid #040404;
    }
  }
  .header__head {
    display: none;
  }
  .navItems {
    margin-top: 0 !important;
  }
  .search__container {
    width: 562px;
    margin-top: -42px;
  }
  .greyNotify {
    display: block;
  }
  .whiteNotify {
    display: none;
  }
}
.userDetailPage {
  background-repeat: no-repeat;
  background-position: right 215px;
}
.bgLines {
  background-repeat: no-repeat;
  background-position: right top;
}
.sort__wrap {
  float: right;
  width: 70px;
  .sort {
    cursor: pointer;
    padding: 0 0 0 25px;
    float: right;
    background-repeat: no-repeat;
    background-position: left center;
    margin-top: -12px;
  }
  .MuiFormControl-root {
    .MuiTypography-body1 {
      font-family: "CircularStd-Book";
      font-size: 12px !important;
    }
  }
}
.location__wrap {
  float: left;
  width: calc(100% - 80px);
  max-width: 350px;
  position: relative;
  .locationSearch,
  .distance {
    width: 50%;
  }
}
#locationSearch {
  float: right;
}
#locationSearchLeft {
  float: left;
}
#distance {
  float: right;
}
.skillSearch {
  margin-bottom: 250px;
  .select__control {
    padding: 0px 20px;
    border-radius: 6px;
    box-shadow: 21px 0 30px 0 rgba(0, 0, 0, 0.08);
    border: solid 1px rgba(151, 151, 151, 0.36);
    background-color: var(--white);
    background-repeat: no-repeat;
    background-position: 97% 5px;
  }
  .select__indicators {
    display: none;
  }
  .select__value-container {
    margin-bottom: -60%;
  }
}
.resultHead {
  font-size: 16px;
  color: #4c4951;
  margin-top: 8px;
}
.connect__box {
  border: 2px solid $color1;
  text-align: center;
  h2 {
    font-size: 24px;
    font-weight: 500;
    line-height: 24px;
    text-align: center;
    color: #000000;
    border-bottom: 2px solid #e5e5ea;
    padding: 18px 0;
    margin: 0;
  }
  h3 {
    font-size: 20px;
    font-weight: 500;
    line-height: 26px;
    text-align: center;
    color: #000000;
    border-bottom: 2px solid #e5e5ea;
    padding: 18px 0;
    margin: 0;
  }
  p {
    font-size: 14px;
    line-height: 18px;
    font-weight: 500;
    text-align: center;
    color: #4c4951;
    padding: 18px 0;
    margin: 0;
  }
  .askQus__box {
    padding: 9px 0;
    object-fit: contain;
    box-shadow: 0 50px 40px -50px rgba(98, 23, 246, 0.44);
    background-image: linear-gradient(to top, #8259ff, #6217f6);
    button {
      width: calc(100% - 18px);
      margin: 0 auto;
    }
  }
}
.padd--15 {
  padding: 15px;
}
.press {
  text-align: center;
  background-repeat: no-repeat;
  background-position: right bottom;
  .press__slider {
    cursor: pointer;
  }
  .slick-prev {
    bottom: -29px;
    left: 0;
    right: 35%;
    top: auto;
    width: auto;
    height: auto;
    margin: 0 auto;
    cursor: pointer;
    z-index: 9999999;
  }
  .slick-next {
    bottom: -29px;
    left: 35%;
    right: 0;
    top: auto;
    width: auto;
    height: auto;
    margin: 0 auto;
    cursor: pointer;
    z-index: 9999999;
  }
  .slick-prev:before,
  .slick-next:before {
    background-color: $color2;
    border-radius: 50%;
  }
  .slick-dots li {
    width: 12px;
    height: 12px;
    button {
      width: 12px;
      height: 12px;
    }
  }
  .slick-dots li.slick-active button:before {
    color: transparent;
    background-color: $color1;
    border: solid 1.5px $color1;
  }
  .slick-dots li button:before {
    border: solid 1.5px #200e32;
    color: transparent;
    width: 12px;
    height: 12px;
    border-radius: 50%;
  }
  .testimonial__name {
    font-size: 14px;
    color: #000000;
    font-weight: bold;
    margin: 25px 0 5px 0;
  }
  .testimonial__desig {
    font-size: 14px;
    color: #a290a5;
    margin: 0 0 5px 0;
  }
}


.announcement {
  text-align: center;
  background-repeat: no-repeat;
  background-position: right bottom;
  .press__slider {
    cursor: pointer;
  }
  .slick-prev {
    bottom: -29px;
    left: 0;
    right: 35%;
    top: auto;
    width: auto;
    height: auto;
    margin: 0 auto;
    cursor: pointer;
    z-index: 9999999;
  }
  .slick-next {
    bottom: -29px;
    left: 35%;
    right: 0;
    top: auto;
    width: auto;
    height: auto;
    margin: 0 auto;
    cursor: pointer;
    z-index: 9999999;
  }
  .slick-prev:before,
  .slick-next:before {
    background-color: $color2;
    border-radius: 50%;
  }
  .slick-dots li {
    width: 9px;
    height: 9px;
  }
  .slick-dots li.slick-active button:before {
    color: transparent;
    background-color: $color1;
    border: solid 1.5px $color1;
  }
  .slick-dots li button:before {
    border: solid 1.5px #200e32;
    color: transparent;
    width: 12px;
    height: 12px;
    border-radius: 50%;
  }
  .testimonial__name {
    font-size: 14px;
    color: #000000;
    font-weight: bold;
    margin: 25px 0 5px 0;
  }
  .testimonial__desig {
    font-size: 14px;
    color: #a290a5;
    margin: 0 0 5px 0;
  }
}

.popup__plain {
  .tab__body {
    padding: 25px 0 5px 0;
  }
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #f5f4f8;
  z-index: 20;
  .popup__content {
    padding: 20px;
  }
  .popup__buttonWrapper {
    float: right;
  }
  img.audioThumb__icon,
  .videoPop__icon {
    width: auto !important;
  }
  .popup__box {
    height: 100%;
    -webkit-animation: fadein 2s;
    -moz-animation: fadein 2s;
    -ms-animation: fadein 2s;
    -o-animation: fadein 2s;
    animation: fadein 2s;
    .popup__wrap {
      position: relative;
      width: 90%;
      max-width: 600px;
      margin: 100px auto 0 auto;
      background-repeat: no-repeat;
      background-position: right top;
      background-image: none;
      img {
        margin-top: -70px;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        max-height: 400px;
        width: 100%;
        object-fit: cover;
        border-radius: 8px;
      }
      h2 {
        margin: 0;
        padding: 0 0 15px 0;
      }
      .button--transparent {
        margin-right: 20px;
      }
      .close__popup {
        color: #4c4951;
        cursor: pointer;
        float: right;
        position: absolute;
        top: -30px;
        right: -25%;
        max-width: 25px;
      }
    }
    .popup__details {
      margin: 15px 0;
      .popup__nameWrap {
        margin: 0 0 25px 0;
        h3 {
          font-size: 20px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.5;
          letter-spacing: normal;
          text-align: left;
          color: #000000;
          margin: 0;
          padding: 0;
          float: left;
          width: 60%;
        }
        .popup__date {
          float: right;
          width: 40%;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.71;
          letter-spacing: normal;
          text-align: right;
          color: #4c4951;
        }
      }
      .popup__date{
        white-space: pre-wrap;
      }
      .popup__data {
        margin-bottom: 15px;
        .popup__label {
          font-size: 12px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.67;
          letter-spacing: normal;
          text-align: left;
          color: #4c4951;
        }
        .popup__desc {
          font-size: 14px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.71;
          letter-spacing: normal;
          text-align: left;
          color: #000000;
        }
      }
    }
  }
}
.creator,
.work {
  background-repeat: no-repeat;
  background-position: left -1px;
  padding: 0 0 0 30px;
}
.url {
  background-repeat: no-repeat;
  background-position: 4px 3px;
  padding: 0 0 0 30px;
}
.rightSidebarFixed {
  position: sticky;
  top: 185px;
  right: 0;
  width: 324px;
  // animation: fadein ease 0.8s;
  // -webkit-animation: fadein ease 0.8s;
  // -moz-animation: fadein ease 0.8s;
  // -o-animation: fadein ease 0.8s;
  // -ms-animation: fadein ease 0.8s;
}
.rightSidebarFixedAfter {
  position: absolute;
  right: 15px;
  bottom: 0;
  width: 324px;
  // animation: fadein ease 0.8s;
  // -webkit-animation: fadein ease 0.8s;
  // -moz-animation: fadein ease 0.8s;
  // -o-animation: fadein ease 0.8s;
  // -ms-animation: fadein ease 0.8s;
}
.blueWrapFixed {
  position: fixed;
  padding: 20px 5% 0 5%;
  top: 0;
  right: 0;
  width: calc(100% - 80px);
  margin: 0;
  max-height: 159px;
  animation: fadein ease 1s;
  -webkit-animation: fadein ease 1s;
  -moz-animation: fadein ease 1s;
  -o-animation: fadein ease 1s;
  -ms-animation: fadein ease 1s;
  transition: ease;
  -webkit-transition: ease;
  -moz-transition: ease;
  -o-transition: ease;
  -ms-transition: ease;
  border-radius: 0px;
  z-index: 7;
  .linePattern {
    right: -11% !important;
    top: -50%;
    bottom: auto !important;
  }
  .profileBannerOval1 {
    display: none;
  }
  .profileBannerOval2 {
    display: none
  }
  .profileBannerOval3 {
    display: none
  }
  .fixedTop__dNone {
    display: none;
  }
  .banner__profile {
    margin-top: 0;
    width: 100px;
    height: 108px;
    float: left;
    border-radius: 8px;
    cursor: pointer;
    img {
      width: 100px;
      height: 108px;
    }
  }
  .banner__profile:after {
    content: "";
    border-radius: 8px;
    position: absolute;
    left: 0;
    top: 0;
    width: 100px;
    height: 108px;
    display: inline-block;
    background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0) 58%,
      var(--black-two) 95%
    );
    z-index: 2;
    opacity: 0.9;
  }
  .banner__profileDetails {
    width: calc(100% - 100px);
    float: right;
    padding: 25px 25px 0 25px;
  }
  .banner__profileText {
    width: calc(100% - 350px);
    float: left;
    display: block !important;
  }
  .banner__profileButtons {
    width: 250px;
    float: right;
  }
  .featuredProf {
    display: none;
  }
  .featuredTag {
    display: block !important;
  }
  .fixedTop__visible {
    display: block;
    text-align: center;
    h3 {
      font-size: 16px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: 0.59px;
      color: #ffffff;
      text-transform: uppercase;
      margin: -20px 0 0 0;
      padding: 0 0 10px 0;
    }
    button {
      margin-top: 0 !important;
    }
    .fixedTop__price {
      font-size: 16px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: 0.59px;
      color: #ffffff;
      margin: 10px 0 25px 0;
      span {
        font-size: 12px;
        font-weight: normal;
      }
    }
  }
  .fixedTop__tab {
    list-style: none;
    margin: 34px 0 0 0;
    padding: 0;
    li {
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: 0.51px;
      text-align: left;
      color: #ffffff;
      padding: 0 0 5px 0;
      margin: 0 40px 0 0;
      float: left;
      cursor: pointer;
      border-bottom: 4px solid transparent;
    }
    li.active {
      border-bottom: 4px solid #ffffff;
    }
  }
}

.read-more-content {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  text-align: left;
  color: #4c4951;
  &--readmore {
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: normal;
    text-align: left;
    color: #6226f4;
    margin-top: -10px;
  }
  &--searchCards {
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.67;
    letter-spacing: normal;
    text-align: left;
    color: #4c4951;
    &--readmore {
      cursor: pointer;
      font-size: 12px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.67;
      letter-spacing: normal;
      text-align: left;
      color: #6226f4;
    }
  }
}
.read-more-content--press {
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #4c4951;
  &--readmore--press {
    cursor: pointer;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    color: #6226f4;
    margin-top: -10px;
  }
}
#experianceRef,
#aboutRefer,
#mediaRefer{
  margin-top: -80px;
  padding-top: 80px;
}
#highlightsRef {
  margin-top: -60px;
  padding-top: 80px;
}
.searchEmpty {
  display: flex;
  flex-direction: column;
  padding: 125px;
  align-items: center;
  justify-content: center;
  .searchEmpty__text {
    flex-grow: 0;
    margin: 0 0 42px;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    color: #4c4951;
    &--profile{
      margin-top: 36px;
    }
  }
  .searchEmpty__image {
    width: 211.9px;
    height: 161.2px;
    align-self: center;
    flex-grow: 0;
    // margin: 42px 91.1px 0 92px;
    object-fit: contain;
    margin: 0 auto;
    &--profile{
      margin-top: -30px;
    }
  }
}
.listingCard__name {
  float: right;
  width: calc(100% - 135px);
}
span.readMoreText {
  color: #6226f4 !important;
  font-weight: 500;
}
.highlights__list {
  li {
    background-repeat: no-repeat;
    background-position: left;
    padding: 0 0 0 32px;
    margin: 0 0 10px 0;
  }
}

.MuiPaper-root.MuiMenu-paper {
  height: 295px !important;
  border-radius: 8px !important;
  width: 161px !important;
  li {
    padding-left: 5px !important;
  }
}
.sortItem {
  height: 41px !important;
}
.sortItem.MuiListItem-root.Mui-selected,
.sortItem.MuiListItem-root.Mui-selected:hover {
  background-color: transparent;
}
.MuiTypography-root {
  font-size: 12px !important;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  text-align: left;
  color: #262626 !important;
  font-family: $baseFont !important;
}
.sortItem__text {
  margin: 0;
}
.filterReferFixed {
  z-index: 10;
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
}
.close__popup {
  width: 20px;
  height: 20px;
}
// .popupSkill .css-1pahdxg-control {
//   height: 44px !important;
//   &:active {
//     height: 44px !important;
//   }
//   &:hover {
//     height: 44px !important;
//     border-color: none;
//   }
//   &:focus {
//     height: 44px !important;
//   }
// }
// .popupSkill .css-yk16xz-control {
//   border-radius: 6px;
//   box-shadow: 21px 0 30px 0 rgba(0, 0, 0, 0.08) !important;
//   border: solid 1px rgba(151, 151, 151, 0.36) !important;
//   background-color: #ffffff !important;
//   height: 44px !important;
//   &:active {
//     height: 44px !important;
//   }
//   &:hover {
//     height: 44px !important;
//   }
//   &:focus {
//     height: 44px !important;
//   }
//   .css-tlfecz-indicatorContainer {
//     display: none !important;
//   }
//   .css-1hb7zxy-IndicatorsContainer {
//     display: none !important;
//   }
//   .css-g1d714-ValueContainer {
//     background-repeat: no-repeat !important;
//     background-position: 97% 9px !important;
//   }
// }
// .popupSkill {
//   .css-g1d714-ValueContainer {
//     background-repeat: no-repeat !important;
//     background-position: 97% 9px !important;
//   }
//   .css-1hb7zxy-IndicatorsContainer {
//     display: none !important;
//   }
// }
.popLeftBgHt {
  height: 575px;
}
.search__container {
  .css-g1d714-ValueContainer {
    background-image: none !important;
  }
}
.ht--0 {
  height: 0;
}
.filterParentRef {
  position: relative;
  padding-bottom: 25px;
  width: 290px;
  padding: 0 15px;
}
.rightListing {
  width: calc(100% - 290px);
  padding: 0 15px;
}
.filterReferFixedAfter {
  margin-bottom: 25px;
}
.addPointer {
  cursor: pointer;
}
.social__footer {
  margin: 0;
  padding: 0;
  list-style: none;
  li {
    list-style: none;
    float: left;
    margin-right: 15px;
    margin-bottom: 11px;
    cursor: pointer;
    &:last-child {
      margin-right: 0;
    }
    img {
      padding: 7px;
    }
  }
}
.cursor--pointer {
  cursor: pointer;
}
.profile {
  background-repeat: no-repeat;
  background-position: right 20%;
  min-height: 95vh;
}
.popup__details.popup__imgWithoutFixed img {
  max-height: none !important;
}
.filterOverFlow .bg--white {
  height: calc(100vh - 50px);
  overflow-y: scroll;
  overflow-x: hidden;
}
.searchSuggest {
  display: none;
  border-radius: 6px;
  box-shadow: 0 -8px 30px 0 rgba(0, 0, 0, 0.08);
  background-color: #ffffff;
  margin: 12px 0 0 0;
  position: absolute;
  width: 100%;
  z-index: 6;
  padding: 15px 20px;
  .searchSuggest__head {
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.17;
    letter-spacing: normal;
    text-align: left;
    color: #4c4951;
    text-transform: uppercase;
    opacity: 0.6;
    margin: 0 0 5px 0;
    span {
      opacity: 1;
      font-size: 12px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 2.17;
      letter-spacing: normal;
      text-align: right;
      color: #6226f4;
      float: right;
      cursor: pointer;
    }
  }
  ul {
    margin: 5px 0 15px 0;
    padding: 0;
    li {
      cursor: pointer;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.71;
      letter-spacing: normal;
      text-align: left;
      color: #4c4951;
      list-style: none;
      padding: 5px 0 5px 5px;
      display: flex;
      align-items: center;
      border-radius: 8px;
      &:hover {
        background-color: rgba(0, 0, 0, 0.04);
      }
      span {
        margin-right: 15px;
      }
      img {
        border-radius: 50%;
        width: 24px;
        height: 24px;
        object-fit: cover;
      }
    }
  }
  .searchSuggest__viewAll {
    border-top: 1px solid #e5e5ea;
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.17;
    letter-spacing: normal;
    text-align: left;
    color: #6226f4;
    padding: 14px 0 0 0;
    cursor: pointer;
  }
}
.searchSuggest__display {
  display: block;
}
.links {
  margin: 80px 0 232px 0;
  max-height: 130px;
  overflow: hidden;
}
  .pressArrows {
    .pressArrows__left {
      float: left;
  }
  .pressArrows__right {
    float: right;
  }
  }
  .press .slick-dots {
    z-index: 3;
    margin: 0 auto;
    width: calc(100% - 70px);
    left: 0;
    right: 0;
    cursor: default;
  }
  .announcement .slick-dots {
    z-index: 3;
  }
.ReactGridGallery .ReactGridGallery_tile:nth-of-type(1){
  width:47.5%!important;
  height: 237px !important;
  overflow: hidden !important;
  border-radius: 10px !important;
}
.ReactGridGallery .ReactGridGallery_tile:nth-of-type(2){
  width:47.5%!important;
  height: 237px !important;
  overflow: hidden !important;
  border-radius: 10px !important;
}
.ReactGridGallery .ReactGridGallery_tile:nth-of-type(3){
  width:31.15%!important;
  height: 160px !important;
  overflow: hidden !important;
  border-radius: 10px !important;
}
.ReactGridGallery .ReactGridGallery_tile:nth-of-type(4){
  width:31.15%!important;
  height: 160px !important;
  overflow: hidden !important;
  border-radius: 10px !important;
}
.ReactGridGallery .ReactGridGallery_tile:nth-of-type(5){
  width:31.15%!important;
  height: 160px !important;
  overflow: hidden !important;
  border-radius: 10px !important;
}
.minHeightContainer {
  min-height: 785px;
}
.medias {
  margin-top: -30px;
}
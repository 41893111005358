@media (max-width: 575.98px) {
}

@media (min-width: 576px) {
}

@media screen and (min-width: 576px) and (max-width: 767.98px) {
}

@media (max-width: 767.98px) {
  .downloadApp__container.popup__container.popup__custom {
    overflow-x: scroll;
    margin: 0;
    .popup__wrap .popup__left, .popup__content {
      width: 100% !important;
    }
    .popup__box {
      height: auto;
    }
    .popup__wrap {
      max-width: 940px;
      margin: 15px 0;
    }
  }  
}

@media (min-width: 768px) {
  .pressArrows {
    position: relative;
    .pressArrows__left {
      float: none;
      position: absolute;
      left: 28%;
      top:4px
  }
  .pressArrows__right {
    float: none;
    position: absolute;
    right: 28%;
    top:4px
  }
  }
  .press .slick-dots {
    width: 40%;
  }
}

@media screen and (min-width: 768px) and (max-width: 991.98px) {
  
}

@media (max-width: 991.98px) {
.downloadApp__container.popup__container.popup__custom {
  // display: block !important;
  .popup__wrap .popup__left, .popup__content {
    width: 50%;
  }
  .popup__content {
  padding: 30px 40px !important;
  }
  .downloadApp .popup__content.donloadApp__right .downloadApp__alignText {
    margin: 20px 0 0 0;
  }
  .close__popup {
    display: none;
  }
  .popup__box .popup__wrap h2 {
    line-height: 32px;
  }
}  
}

@media (min-width: 992px) {
}

@media screen and (min-width: 992px) and (max-width: 1199.98px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 875px !important;
  }
  .bannerBlueWrap__body {
    max-width: 875px;
  }  
  .rightSidebarFixed, .rightSidebarFixedAfter {
    width: 334.58px;
  }
  .ReactGridGallery .ReactGridGallery_tile:nth-of-type(5) {
    width: 30.8% !important;
  }
  .ReactGridGallery .ReactGridGallery_tile:nth-of-type(4) {
    width: 30.8% !important;
  }
  .ReactGridGallery .ReactGridGallery_tile:nth-of-type(3) {
    width: 30.8% !important;
  }
  .bannerBlueWrap.blueWrapFixed {
    background-size: cover;
  }
  .bannerBlueWrap {
    background-size: auto;
  }
}

@media (max-width: 1199.98px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    width: 100%;
  }
}

@media (min-width: 1200px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1062px;
  }
  .bannerBlueWrap__body {
    max-width: 1280px;
    width: 100%;
    margin: 0 auto;
  }
}
// @media screen and (min-width: 1350px) and (max-width: 1449.98px) {
//   .rightSidebarFixed {
//     right: 5.9%;
//     width: 26.3%;
//   }
// }
// @media screen and (min-width: 1450px) and (max-width: 1549.98px) {
//   .rightSidebarFixed {
//     right: 5.9%;
//   }
// }
// @media screen and (min-width: 1550px) and (max-width: 1649.98px) {
//   .rightSidebarFixed {
//     right: 6.9%;
//   }
// }
// @media screen and (min-width: 1650px) and (max-width: 1799.98px) {
//   .rightSidebarFixed {
//     right: 8.9%;
//   }
// }
// @media screen and (min-width: 1200px) and (max-width: 1499.98px) {
//   .filterReferFixed {
//     width: 20%;
// }
// }

// @media (max-width: 1499.98px) {
// }

// @media (min-width: 1500px) {
//   .filterReferFixed {
//     width: 290px;
// } 
// }
// @media (min-width: 1800px) {
//   .rightSidebarFixed {
//     right: 13.9%;
//   }
// }

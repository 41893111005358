button {
  cursor: pointer;
}
.button {
  font-size: 13px;
  border-radius: 6px;
  border: 0;
  padding: 8px 25px;
  text-align: center !important;
  &:hover {
    opacity: 0.8;
    outline: none;
  }
  &:focus {
    outline: none;
  }
  &:active {
    outline: none;
  }
}
.button--transparent {
  padding: 7px 46px 7px 47px;
  border-radius: 6px;
  background-color: transparent;
  color: $white;
  border: 1px solid $white;
  &:hover {
    background-color: $white;
    color: #000;
  }
}
.button--withoutBg {
  padding: 7px 15px;
  border-radius: 0;
  background-color: transparent;
  color: $color1;
  border: none;
  text-transform: uppercase;
  &:hover {
    background-color: transparent;
    color: $color1;
    outline: none;
  }
  &:focus {
    background-color: transparent;
    color: $color1;
    outline: none;
  }
}
.button--back {
  font-size: 16px;
  padding: 7px 15px 7px 30px;
  border-radius: 0;
  background-color: transparent;
  color: #4c4951;
  border: none;
  line-height: 1.5;
  background-repeat: no-repeat;
  background-position: 0 center;
  max-width: 200px;
  &:hover {
    background-color: transparent;
    color: #4c4951;
    outline: none;
  }
  &:focus {
    background-color: transparent;
    color: #4c4951;
    outline: none;
  }
}
.button--primary {
  padding: 7px 20px;
  color: $white;
  border-radius: 6px;
  box-shadow: 0 10px 10px -6px var(--purply-blue-50);
  background-image: linear-gradient(102deg, #8259ff, #6217f6 89%);
  &:hover {
    opacity: 1;
    background-image: linear-gradient(102deg, #8259ff, #6217f6 50%);
  }
}
.button--disabled {
  padding: 6px 40px;
  color: $white;
  border-radius: 6px;
  // box-shadow: 0 10px 10px -6px var(--purply-blue-50);
  background-image: linear-gradient(102deg, #C5C4C3, #C5C4C3 89%);
}
.button__violet {
  color: $white;
  padding: 10px 151px;
  border-radius: 6px;
  box-shadow: 0 10px 10px -6px var(--purply-blue-50);
  background-image: linear-gradient(102deg, #8259ff, #6217f6 89%);
}
.button--white {
  color: #6226f4;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  background-color: #ffffff;
}
.button--shadow {
  padding: 10px 48px 10px 47px;
  color: #6226f4;
  border-radius: 6px;
  box-shadow: 1px 1px 20px 0 rgba(0, 0, 0, 0.15);
  background-color: #ffffff;
  text-align: center;
  border: 0;
  &:hover {
    border: 0;
    outline: 0;
    background-color: $color1;
    color: #ffffff;
  }
  &:focus {
    border: 0;
    outline: 0;
  }
}
.button__violetBorder {
  color: #6226f4;
  font-size: 16px;
  line-height: 1.5;
  border: 1px solid $color1;
  background-color: transparent;
  width: 100%;
}
.viewAllButton {
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.17;
  letter-spacing: normal;
  text-align: left;
  color: #6226f4;
  border: 0;
  background-color: transparent;
  padding: 0;
  &:hover {
    outline: none;
    border: 0;
  }
  &:focus {
    outline: none;
    border: 0;
  }
  &:active {
    outline: none;
    border: 0;
  }
  img{
    margin-bottom: 2px;
  }
}
.people__viewAll {
  img {
    width: 12px;
  }
}

.header, .header--search, .header--welcome {
    background-color: $color1;
    background-size: cover;
    background-position: center;
}
.header {
    padding: 0px 0 50px 0;
}
.bg--white {
    background-color: #ffffff;
    border-radius: 8px;
}
.width--100 {
    width: 100%;
}
input, select {
    margin: 0 0 15px 0 !important;
}
input[type="radio"] {
    margin-bottom: 0 !important;
}
input[type="radio"] + label {
    margin-left: 10px;
}
.form-check-input {
    margin-top: 6px !important;
}
.form-check-label {
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.67;
    letter-spacing: normal;
    color: var(--charcoal-grey);
    margin: 0 0 0 25px;
    max-width: 378px;
}
label{
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.67;
    letter-spacing: normal;
    color: var(--charcoal-grey);
    a{
        text-decoration: underline;
        color: var(--charcoal-grey);
    }
}

.switch {
    position: relative;
    display: inline-block;
    width: 30px;
    height: 16px;
  input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }
  .slider:before {
    position: absolute;
    content: "";
    height: 12px;
    width: 12px;
    left: 4px;
    bottom: 2px;
    background-color: $white;
    -webkit-transition: .4s;
    transition: .4s;
  }
  input:checked + .slider {
    background-color: $color5;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px $color5;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(10px);
    -ms-transform: translateX(10px);
    transform: translateX(10px);
  }
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }
}
  
.autocomplete {
  position: relative;
  display: inline-block;
  input {
    border: 1px solid transparent;
    background-color: #f1f1f1;
    padding: 10px;
    font-size: 16px;
  }
  
  input[type=text] {
    background-color: #f1f1f1;
    width: 100%;
  }
  
  input[type=submit] {
    background-color: DodgerBlue;
    color: #fff;
    cursor: pointer;
  }
  .autocomplete-items {
    position: absolute;
    border: 1px solid #d4d4d4;
    border-bottom: none;
    border-top: none;
    z-index: 99;
    top: 100%;
    left: 0;
    right: 0;
  }
  
  .autocomplete-items div {
    padding: 10px;
    cursor: pointer;
    background-color: #fff; 
    border-bottom: 1px solid #d4d4d4; 
  }
  
  .autocomplete-items div:hover {
    background-color: #e9e9e9; 
  }
  
  .autocomplete-active {
    background-color: DodgerBlue !important; 
    color: #ffffff; 
  }
}
.distance__popup {
  padding: 15px;
  text-align: center;
  position: absolute;
  z-index: 99;
  border-radius: 6px;
  box-shadow: 0 7px 20px 0 rgba(0, 0, 0, 0.15);
  background-color: var(--white);
  width: 100%;
  top: 36px;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  .distanceBtn__wrap {
    width: 100%;
    border-top: 1px solid #e5e5ea;
  }
}
#miles {
  width: 42px;
  text-align: center;
}
.password__wrap {
  position: relative;
  .inputIcon--password {
    position: absolute;
    left: 91%;
    top: 40px;
    border: 0;
    outline: 0;
    &:hover {
      border: 0;
      outline: 0;
    }
    &:active {
      border: 0;
      outline: 0;
    }
    &:focus {
      border: 0;
      outline: 0;
    }
}
}
  
  
  
  
  
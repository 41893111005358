.left__navigation {
  background-color: #ffffff;
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.07);
  width: 80px;
  float: left;
  padding: 25px;
  min-height: calc(100vh);
  height: 100%;
  position: fixed;
  transition: width 1s;
  z-index: 8;
  background-image: none;
  .logo {
    width: 35px;
    overflow: hidden;
    margin-bottom: 45.2px;
    img {
      min-width: 140px;
      max-width: 140px;
    }
  }

  .logo__background {
    opacity: 0.5;
    -webkit-filter: blur(50px);
    filter: blur(50px);
    background-color: $color1;
    width: 50px;
    height: 100px;
    position: absolute;
    left: 0;
    top: 0;
    border: 1px solid #000;
    z-index: -1;
  }
  .menu__wrapper.collapse {
    display: block;
  }
  .menu {
    list-style: none;
    margin: 0;
    padding: 0;
    .item.active {
      opacity: 1 !important;
      div {
        font-weight: 500;
        color: #6a37fd;
      }
    }
    .item {
      background-size: 24px;
      background-repeat: no-repeat;
      background-position: 4px 0;
      padding: 0 0 40px 0;
      height: 65px;
      div {
        width: 100%;
        color: $color2;
        list-style: none;
        text-decoration: none;
        padding: 0 0 0 44px;
        opacity: 0;
        text-transform: capitalize;
        cursor: pointer;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        text-align: left;
        color: #4c4951;
        // div {
        //   padding: 10px 15px;
        //   border-bottom: 1px solid #cccccc;
        // }
      }
      a {
        width: 100%;
        color: $color2;
        list-style: none;
        text-decoration: none;
        padding: 0 0 0 44px;
        opacity: 0;
        text-transform: capitalize;
        &:hover {
          text-decoration: none;
        }
        div {
          padding: 10px 15px;
          border-bottom: 1px solid #cccccc;
        }
      }
      &:last-child {
        a div {
          border-bottom: 0;
        }
      }
    }
  }
  .navClose.navbar__toggle span {
    background: transparent;
    &:after {
      transform: rotate(45deg) translate(-4px, -4px);
    }
    &:before {
      transform: rotate(-45deg) translate(-3px, 3px);
    }
  }
  .navClose.navbar__toggle span:after {
    width: 100%;
  }
  .navBar {
    position: absolute;
    right: -17px;
    top: 25px;
    background-color: #fff;
    display: flex;
    align-items: center;
    width: 30px;
    height: 30px;
    padding: 0;
    justify-content: center;
    border: 1px solid #dbe4ff;
    border-radius: 50%;
  }
  .navbar__toggle {
    width: 14px;
    position: relative;
    cursor: pointer;
    input {
      position: absolute;
      right: -18px;
      top: -16px;
      opacity: 0;
      cursor: pointer;
      width: 50px;
      height: 35px;
      margin: 0;
    }
    span {
      position: absolute;
      right: 0;
      width: 100%;
      height: 2px;
      background: $color2;
      &:before,
      &:after {
        content: "";
        position: absolute;
        height: 2px;
        background: $color2;
        top: -5px;
        transition: 0.3s;
      }
      &:before {
        width: 100%;
      }
      &:after {
        width: 50%;
        top: 5px;
      }
    }
  }
}
.left__navigation.collapse {
  background-repeat: no-repeat;
  background-position: 0 90%;
}
.dashboard {
  .left__navigation.collapse {
    display: block;
    width: 250px;
    z-index: 99;
    .menu .item a,
    .menu .item div,
    .logo {
      -webkit-animation: fadein 1.2s ease;
      animation: fadein 1.75s 0s 1 normal forwards;
    }
    .logo {
      width: 145px;
    }
  }
  .searchTab {
    margin-top: -44px;
    border-bottom: none;
    .nav-link.active {
      font-weight: bold;
      border-bottom: 4px solid #fff;
    }
    .nav-link {
      color: $white !important;
      font-size: 16px;
      line-height: 2.5;
      letter-spacing: 0.59px;
      margin: 0 25px 0 0;
      padding: 0;
      border: 0;
      background-color: transparent;
    }
  }
  .searchTab--colleagues {
    margin-top: -64px;
    border-bottom: none;
    .nav-link.active {
      font-weight: bold;
      border-bottom: 4px solid #fff;
    }
    .nav-link {
      color: $white !important;
      font-size: 16px;
      line-height: 2.5;
      letter-spacing: 0.59px;
      margin: 0 25px 0 0;
      padding: 0;
      border: 0;
      background-color: transparent;
      display: flex;
      align-items: center;
      justify-content: center;
      .badge {
        margin: 0 3px 0 8px;
      }
    }
  }
  .search__container {
    max-width: 562px;
    position: relative;
    button {
      position: absolute;
      top: 5px;
      right: 5px;
      width: 134px;
      font-size: 12px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.67;
      letter-spacing: 0.5px;
      color: #ffffff;
    }
  }
  .loginTab {
    border-bottom: none;
    .nav-link.active {
      font-weight: bold;
      border-bottom: 4px solid $color1;
      color: var(--purplish-blue-two);
    }
    .nav-link {
      margin: 0 20px 0 0;
      border: 0;
      background-color: transparent;
      font-size: 24px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.75;
      letter-spacing: 0.5px;
      color: var(--black);
    }
  }
  .loginTab--colleagues {
    border-bottom: none;
    margin-top: 20px;
    .nav-link.active {
      font-weight: bold;
      border-bottom: 3px solid $color1;
      color: var(--purplish-blue-two);
    }
    .nav-link {
      margin: 0 0px 0 0;
      border: 0;
      background-color: transparent;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.75;
      letter-spacing: 0.5px;
      color: var(--black);
    }
  }
  .tab__body {
    text-align: left;
  }
  .header--search.searchDetailHeader {
    padding: 28px 0 28px 0;
  }
  .header--search {
    padding: 28px 0 75px 0;
    .navbar {
      padding: 0;
    }
    .navItems {
      margin-top: -43px;
    }
    h4 {
      margin: 0 0 40px 0;
      color: var(--white);
    }
    .explore__explain {
      width: 828px;
      height: 44px;
      flex-grow: 0;
      margin: 18px 252px 0 2px;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.57;
      letter-spacing: normal;
      text-align: left;
      color: #ffffff;
      margin-bottom: -62px;
    }
  }
  .search__container {
    input {
      width: 562px;
      height: 44px;
      padding: 10px 150px 10px 15px;
      border-radius: 6px;
      margin-bottom: 0 !important;
      border: 2px solid #efeff7;
      box-shadow: 10px 0px 35px 0px rgba(64, 57, 78, 0.1);
      &:focus {
        border: none;
        outline: none;
        border: 2px solid #efeff7;
      }
    }
    button {
      margin-left: -138px;
    }
  }
  .userType__container {
    width: 100%;
    max-width: 750px;
    margin: 50px auto;
    button {
      margin: 25px auto;
      width: 375px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .userType__container__col {
      padding: 20px;
      border-radius: 6px;
      box-shadow: 0 4px 30px 0 rgba(0, 0, 0, 0.08);
      border: solid 1px #f8f7fa;
      background-color: var(--white);
      height: 100%;
      input {
        float: right;
      }
      h5 {
        margin: 0;
        padding: 0 0 10px 0;
      }
      p {
        padding: 0 0 10px 0;
        border-bottom: 1px solid #e5e5ea;
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.67;
        letter-spacing: normal;
        text-align: left;
        color: #4c4951;
      }
      ul {
        list-style: none;
        li {
          position: relative;
          padding-left: 1.5em;
          font-size: 12px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.67;
          letter-spacing: normal;
          text-align: left;
          color: #262626;
          margin: 0;
          margin-bottom: 6px;
          &:after {
            content: "";
            height: 7px;
            width: 7px;
            background: #b8b5bd;
            display: block;
            position: absolute;
            transform: rotate(45deg);
            top: 6px;
            left: 0;
          }
        }
      }
    }
  }
  .highlights__container {
    border-radius: 6px;
    background-color: #efeff7;
    padding: 40px 0;
    .highlights__wrap {
      h5 {
        text-align: center;
        margin: 0 0 25px 0;
      }
      width: 100%;
      max-width: 750px;
      margin: 0 auto;
      .highlights__list {
        list-style: none;
        float: left;
        width: 33.33%;
        li {
          font-size: 12px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.67;
          letter-spacing: normal;
          color: var(--charcoal-grey);
        }
      }
    }
  }
}

.header--welcome {
  padding: 0px 0 50px 0;
  background-position: center;
  h1,
  p {
    text-align: center;
    color: #fff;
  }
}

.carousel__container {
  margin: 0 auto;
  width: 28px;
  span {
    color: #fff;
    font-size: 0;
    border-radius: 50%;
    line-height: 0;
    margin-right: 8px;
    padding: 4px;
    border: 1px solid #fff;
    cursor: pointer;
    &:hover {
      text-decoration: none;
    }
  }
  span.active {
    background-color: $white;
  }
}
.header--welcome {
  min-height: 86px;
}

.userDetails__container {
  width: 100%;
  max-width: 750px;
  margin: 50px auto;
  h2 {
    text-align: center;
  }
  .uploadBtn {
    margin: 25px auto;
    width: 375px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .uploadBtn__wrapper {
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto 25px auto;
    .uploadBtn {
      width: 202px;
    }
    button {
      opacity: 0.8;
      border: 1px dashed #4c4951;
      padding: 16px !important;
      border-radius: 6px;
      .button__inner {
        background-color: #fff;
        padding: 35px 24px;
        border-radius: 6px;
        box-shadow: 0 4px 30px 0 rgba(0, 0, 0, 0.08);
        border: solid 1px #f8f7fa;
        background-color: #ffffff;
        img {
          margin-bottom: 10px;
        }
      }
      span {
        cursor: pointer;
        font-size: 12px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.67;
        letter-spacing: normal;
        text-align: center;
        color: #6226f4;
      }
      .error {
        margin-top: 0;
      }
    }
    input[type="file"] {
      position: absolute;
      top: 25px;
      left: 0;
      right: 0;
      margin: 0 auto !important;
      opacity: 0;
      cursor: pointer;
      width: 202px;
      height: 166px;
    }
    .uploadedImg {
      width: 201px;
      height: 160px;
      margin: 25px auto !important;
      padding: 15px;
      border: 1px dashed #4c4951;
      object-fit: cover;
      border-radius: 8px;
    }
  }
}

.professional__group {
  margin: 20px 0;
  &--extraTopMargin {
    margin-top: 50px;
  }
  h2 {
    margin: 0;
  }
}
.professional__sliderWrap {
  margin: 50px 0 0 0;
  h2 {
    margin: 0 !important;
  }
}
.viewAll,
.peopleViewAll {
  width: 218px;
  height: 270px;
  margin-right: 15px;
  margin-bottom: 0;
  background-color: $color1;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 6px;
  box-shadow: 0 20px 35px -16px rgba(64, 57, 78, 0.15);
  background-size: cover;
  cursor: pointer;
  .viewAll__container {
    .viewAll__arrow {
      margin: auto;
    }
    h5 {
      font-size: 14px;
      font-weight: 500;
      line-height: 1.71;
      letter-spacing: 0.51px;
      text-align: center;
      color: #ffffff;
    }
  }
}
.phoneNo__wrap {
  width: 100%;
  .phoneNo__code {
    width: 50px;
    float: left;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    border-right: 0;
  }
  .phoneNo {
    width: calc(100% - 50px);
    float: right;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
  }
}
.peopleCard {
  width: 218px;
  height: auto;
  border-radius: 6px;
  box-shadow: 0 20px 35px -16px rgba(64, 57, 78, 0.15);
  background-color: #ffffff;
  margin-right: 19px;
  margin-bottom: 30px;
  padding: 0 0 16px;
  position: relative;
  &--highlights {
    margin: 0;
    border-radius: 6px;
    box-shadow: 0 20px 35px -16px rgba(64, 57, 78, 0.15);
    background-color: #ffffff;
    position: relative;
    .highlightDate {
      position: absolute;
      top: 15px;
      right: 15px;
      font-size: 12px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.83;
      letter-spacing: 0.44px;
      text-align: center;
      color: #4c4951;
      background-color: #ffffff;
      padding: 0 8px;
      border-radius: 8px;
    }
  }
  cursor: pointer;
  .card-img-top {
    width: 218px;
    height: 218px;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    object-fit: cover;
    margin-bottom: 15px;
    &--highlights {
      width: 218px;
    }
  }
  .peaopleCard__title {
    margin: 0px 5px 0px 16px;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: left;
    color: #000000;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 170px;
  }
  .peaopleCard__title--highlights {
    margin: 0px 5px 0px 16px;
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: left;
    color: #000000;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 180px;
  }
  .peopleCard__dot {
    width: 6px;
    height: 6px;
    margin-left: 1px;
    background-color: #00bec0;
    border-radius: 6px;
  }
  .peaopleCard__desc {
    font-size: 14px;
    margin: 0 0px 0px 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: normal;
    text-align: left;
    color: #4c4951;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 170px;
  }
  .overlay__image {
    position: absolute;
    right: 0;
    left: 0;
    top: 0;
    width: 218px;
    height: 218px;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    background-image: radial-gradient(circle at 0 0, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.81));
    z-index: 3;
  }
  .peopleCard__button {
    width: 24px;
    height: 24px;
    position: absolute;
    top: 12px;
    right: 12px;
    border: 0;
    flex-grow: 0;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
    background-color: #4504c8;
    border-radius: 50%;
    z-index: 4;
    img{
      margin-bottom: 4px;
    }
  }
}
.locationSearch,
.distance {
  border-radius: 4px;
  border: solid 1px #dadadf;
  background-color: #ffffff;
  padding: 5px 15px 5px 35px;
  background-repeat: no-repeat;
  background-position: 10px center;
}
.travel,
.online,
.travelTo,
.beginner,
.intermediate,
.advanced {
  background-repeat: no-repeat;
  background-position: 0 -1px;
  padding-left: 30px !important;
  text-align: left !important;
}
.likeIconBg {
  background-repeat: no-repeat;
  background-position: 0 center;
  padding: 3px 0 3px 30px;
}
.customFilter__choose {
  width: 100%;
}
.peopleSkills {
  padding: 0px 10px 0px 10px;
  border-radius: 4px;
  background-color: #efeff7;
  color: #4c4951;
  font-size: 12px;
  width: auto;
  float: left;
  margin: 0 5px 8px 0;
  &:hover {
    background-color: $color5;
    color: $white;
  }
}
.filters {
  .filters__head {
    padding: 15px;
    border-bottom: 2px solid #e5e5ea;
    h5 {
      font-size: 16px;
      float: left;
      margin: 0;
      padding: 0;
    }
    button {
      float: right;
      margin: 0;
      padding: 0;
    }
  }
  .filters__subhead {
    font-size: 12px;
    font-weight: bold;
    line-height: 2.17;
    text-align: left;
    color: #000000;
    text-transform: uppercase;
  }
  .button--withoutBg {
    padding: 0;
    margin: 10px 0 0 0;
  }
  .filters__body {
    padding: 15px;
    .online {
      font-size: 14px;
    }
    .filters__LRContainer {
      margin: 0 0 15px 0;
      .filters__leftText {
        width: calc(100% - 40px);
        float: left;
        font-size: 14px;
      }
      .filters__rightButton {
        width: 40px;
        text-align: right;
        float: right;
      }
    }
  }
}

.searchDetail__wrap1 {
  margin: 25px 0;
}
.skillsWithClose {
  padding: 1px 0 1px 8px;
  border-radius: 4px;
  background-color: #efeff7;
  color: #4c4951;
  font-size: 12px;
  width: auto;
  float: left;
  margin: 3px 4px 3px 4px;
  span {
    padding: 15px 8px 15px 10px;
    font-size: 12px;
    cursor: pointer;
  }
}
.categoriesSort {
  padding: 12px 20px;
  border-radius: 6px;
  background-color: #ffffff;
  margin: 0 0 25px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  .categoriesSort__wrap {
    width: 85%;
    float: left;
    border-right: 1px solid #e5e5ea;
  }
  .categoriesSort__buttonWrap {
    width: 15%;
    float: right;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.listingCard {
  border-radius: 6px;
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.04);
  border: solid 1px rgba(228, 228, 228, 0.36);
  background-color: #ffffff;
  display: flex;
  margin: 0 0 25px 0;
  .listingCard__wrap1 {
    padding: 20px;
    border-right: 2px solid #e5e5ea;
    width: 65%;
    float: left;
    .listingCard__img {
      cursor: pointer;
      width: 150px;
      height: 135px;
      float: left;
      padding-right: 15px;
      border-radius: 8px;
      img {
        border-radius: 8px;
        width: 150px;
        height: 135px;
        object-fit: cover;
      }
    }
    .listingCard__name {
      width: calc(100% - 150px);
      h5 {
        margin: 0 0 7px 0;
        cursor: pointer;
        span {
          color: #4c4951;
          font-weight: normal;
        }
      }
      .likeIconBg {
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 12px;
        letter-spacing: normal;
        text-align: left;
        color: #4c4951;
        display: inline-block;
      }
    }
    p {
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.67;
      letter-spacing: normal;
      text-align: left;
      color: #4c4951;
    }
  }
  .listingCard__wrap2 {
    padding: 20px;
    width: 35%;
    float: right;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    h3 {
      font-size: 24px;
      font-weight: bold;
      line-height: 1.08;
      color: #262626;
    }
    p {
      text-align: center;
      padding: 0 0 7px 0;
      margin: 0;
    }
    button {
      margin: 0 0 15px 8px;
    }
    .online {
      display: none;
    }
    .travel {
      display: none;
    }
    .yes {
      display: block !important;
    }
  }
}
.listingCard__activity {
  border-radius: 6px;
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.04);
  border: solid 1px rgba(228, 228, 228, 0.36);
  background-color: #ffffff;
  display: flex;
  margin: 0 0 25px 0;
  .listingCard__wrap1 {
    padding: 20px;
    float: left;
    .listingCard__img {
      cursor: pointer;
      width: 150px;
      height: 135px;
      float: left;
      padding-right: 15px;
      border-radius: 8px;
      img {
        border-radius: 8px;
        width: 150px;
        height: 135px;
        object-fit: cover;
      }
    }
    .listingCard__name {
      width: calc(100% - 150px);
      h5 {
        margin: 0 0 7px 0;
        cursor: pointer;
        span {
          color: #4c4951;
          font-weight: normal;
        }
      }
      .likeIconBg {
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 12px;
        letter-spacing: normal;
        text-align: left;
        color: #4c4951;
        display: inline-block;
      }
    }
    p {
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.67;
      letter-spacing: normal;
      text-align: left;
      color: #4c4951;
    }
  }
}
.bannerImage {
  border-radius: 8px;
  background-size: cover;
  img {
    border-radius: 8px;
    width: 100%;
    height: 534px;
    object-fit: cover;
    cursor: pointer;
  }
}
.featuredProf {
  padding: 4px 10px;
  border-radius: 14px;
  background-color: #feb22d;
  color: #ffffff;
  text-transform: uppercase;
  font-size: 12px;
  text-align: center;
  width: 85%;
  margin: 0;
  position: absolute;
  bottom: 14px;
  left: 17px;
  right: 0;
  z-index: 10;
}
.bannerBlueWrap {
  background-repeat: no-repeat;
  background-size: cover;
  width: 95%;
  margin: -17% auto 0 auto;
  z-index: 1;
  display: block;
  position: relative;
  height: 198px;
  border-radius: 10px;
  box-shadow: 0 50px 40px -50px rgba(98, 23, 246, 0.44);
  animation: fadein ease 1.8s;
  -webkit-animation: fadein ease 1.8s;
  -moz-animation: fadein ease 1.8s;
  -o-animation: fadein ease 1.8s;
  -ms-animation: fadein ease 1.8s;
  transition: ease;
  -webkit-transition: ease;
  -moz-transition: ease;
  -o-transition: ease;
  -ms-transition: ease;
  .fixedTop__visible {
    display: none;
  }
  .banner__profile {
    border-radius: 8px;
    width: 250px;
    height: 259px;
    float: left;
    margin-top: -28px;
    position: relative;
    object-fit: contain;
    cursor: pointer;
    overflow: hidden;
    margin-left: -15px;
    img {
      border-radius: 8px;
      object-fit: cover;
      width: 250px;
      height: 259px;
    }
    .featuredTag {
      display: none;
      position: absolute;
      left: 3px;
      top: -2px;
      width: 23px;
      height: 34px;
      img {
        width: 23px;
        height: 34px;
      }
    }
  }
  .banner__profile.isFeatured:after {
    content: "";
    border-radius: 8px;
    position: absolute;
    left: 0;
    top: 0;
    width: 250px;
    height: 100%;
    display: inline-block;
    background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0) 58%,
      var(--black-two) 95%
    );
    z-index: 2;
    opacity: 0.9;
  }
  .name__verified {
    background-repeat: no-repeat;
    background-position: right center;
    width: fit-content;
    padding: 0 25px 0 0;
    line-height: 1.5;
    letter-spacing: 0.73px;
    text-align: left;
    color: #ffffff;
    font-size: 20px;
    margin: 0 0 12px 0;
  }
  .shareIcon {
    cursor: pointer;
    margin: 0 0 0 20px;
    object-fit: contain;
    &:hover {
      opacity: 0.8;
    }
  }
  .addIcon {
     cursor: pointer;
    padding: 2px;
    &:hover {
      opacity: 0.8;
    }
  }
  .engagements {
    font-size: 14px;
    line-height: 1;
    letter-spacing: 0.51px;
    text-align: left;
    color: #ffffff;
    background-repeat: no-repeat;
    background-position: left 0px;
    padding: 5px 0 5px 25px;
    margin: 12px 0 0 0;
  }
  .locationWhite {
    background-repeat: no-repeat;
    background-position: left center;
    line-height: 1;
    letter-spacing: 0.51px;
    font-size: 14px;
    color: #ffffff;
    padding: 0 0 0 25px;
    margin: 0 0 12px 0;
  }
  .banner__profileDetails {
    width: calc(100% - 235px);
    float: right;
    padding: 25px;
    height: 100%;
    position: relative;
    // animation: fadein ease 0.5s;
    // -webkit-animation: fadein ease 1s;
    // -moz-animation: fadein ease 0.5s;
    // -o-animation: fadein ease 0.5s;
    // -ms-animation: fadein ease 0.5s;
    // transition: ease 0.2s;
    // -webkit-transition: ease 0.2s;
    // -moz-transition: ease 0.2s;
    // -o-transition: ease 0.2s;
    // -ms-transition: ease 0.2s;
    .linePattern {
      position: absolute;
      right: -10px;
      bottom: -40px;
      flex-grow: 0;
      object-fit: cover;
      z-index: -1;
      height: 130px;
      -webkit-mask-image: linear-gradient(to top, transparent 10%, #fff);
      mask-image: linear-gradient(to top, transparent 10%, #fff);
    }
    .profileBannerOval1 {
      position: absolute;
      top: 0px;
      left: 0px;
      flex-grow: 0;
      object-fit: contain;
      opacity: 0.5;
      z-index: -1;
    }
    .profileBannerOval2 {
      position: absolute;
      top: 0px;
      left: -30px;
      flex-grow: 0;
      object-fit: contain;
      opacity: 0.5;
      z-index: -1;
    }
    .profileBannerOval3 {
      position: absolute;
      top: 0px;
      right: 0px;
      flex-grow: 0;
      object-fit: contain;
      opacity: 0.5;
      z-index: -1;
    }
    .banner__profileText {
      width: calc(100% - 188px);
      float: left;
      .peopleSkills {
        border-radius: 11px;
        background-color: #ebe3ff;
        font-size: 10px;
        font-weight: 500;
        line-height: 2;
        color: #8054ff;
        padding: 3px 15px;
        font-stretch: normal;
        font-style: normal;
        line-height: 2;
        letter-spacing: normal;
      }
    }
    .banner__profileButtons {
      width: 188px;
      float: right;
      text-align: right;
      button {
        margin-top: 75px;
      }
    }
  }
}
.margin--tb50 {
  margin: 50px 0;
}
.margin--tb80 {
  margin: 80px 0;
}
.margin--b50 {
  margin: 0 0 50px 0;
}
span.short-text {
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  text-align: left;
  color: #4c4951;
}
.press {
  span.short-text {
    text-align: center;
  }
}
.announcement {
  span.short-text {
    text-align: center;
  }
}
.linksCard {
  border-radius: 6px;
  margin-right: 20px;
  padding: 10px;
  border: solid 1px #e5e5ea;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  overflow: hidden;
  width: 251px;
  height: 66px;
  cursor: pointer;
  .links__img {
    width: 30px;
    height: 30px;
    margin-right: 14px;
  }
  .links__text {
    width: 80%;
    h5 {
      white-space: nowrap;
      text-overflow: ellipsis;
      margin-bottom: 2px;
    }
    .links__text__desc {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      margin: 0;
    }
  }
}
.profile {
  h3 {
    margin: 0 0 25px 0;
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.75;
    letter-spacing: 0.6px;
    text-align: left;
    color: #000000;
  }
}

.normal__tabs {
  border-bottom: none;
  margin-bottom: 25px;
  .nav-link:focus,
  .nav-link:hover {
    border-color: transparent;
  }
  .nav-item.show .nav-link,
  .nav-link.active {
    background-color: transparent;
    border: none;
    border-bottom: 3px solid $color1 !important;
    color: #6317f6;
  }
  .nav-link {
    color: #4c4951;
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.75;
    letter-spacing: 0.5px;
    text-align: left;
    border-bottom: 3px solid transparent !important;
  }
}

.expCard {
  margin: 0 0 10px;
  padding: 10px 0 10px;
  object-fit: contain;
  border-radius: 8px;
  box-shadow: 0 20px 35px -16px rgba(64, 57, 78, 0.15);
  background-color: #ffffff;
  cursor: pointer;
  &--active {
    border: solid 1px #6216f6;
    background-color: #ffffff;
    color: #4c4951;
    box-shadow: 0 20px 35px -16px rgba(64, 57, 78, 0.15);
  }
  .expCard__wrap1 {
    padding-left: 15px;
    padding-right: 15px;
    background-repeat: no-repeat;
    background-position: 93% top;
    border-bottom: 1px solid #e5e5ea;
    .expCard__skill {
      padding: 0px 10px 0px 10px;
      border-radius: 10px;
      background-color: #efeff7;
      color: #4c4951;
      font-size: 12px;
      width: auto;
      float: left;
      margin: 0 8px 15px 0;
    }
    .expCard__nameWrap {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      margin-bottom: 15px;
      .expCard__letter {
        width: 42px;
        height: 42px;
        background-image: linear-gradient(to top, #4a1194, #3e008d);
        border-radius: 5px;
        color: $white;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .expCard__name {
        width: calc(100% - 42px);
        padding: 0 0 0 15px;
        h5 {
          margin-bottom: 0px;
          font-size: 14px;
          font-weight: bold;
        }
        p {
          margin-bottom: 0px;
          color: #4c4951;
          font-size: 12px;
        }
      }
    }
  }
  .expCard__wrap2 {
    padding: 10px 15px 0 15px;
    .expCard__location {
      width: calc(100% - 65px);
      float: left;
      color: #4c4951;
      font-size: 12px;
      background-repeat: no-repeat;
      background-position: left;
      padding: 0 0 0 25px;
    }
    .expCard__date {
      width: 58px;
      float: right;
      text-align: right;
      color: #4c4951;
      font-size: 12px;
      background-repeat: no-repeat;
      background-position: left center;
    }
  }
}

.expDetail {
  width: 100%;
  box-shadow: 0 20px 35px -16px rgba(0, 0, 0, 0.07);
  border-radius: 8px;
  .expDetail__header {
    padding: 25px;
    background-repeat: no-repeat;
    background-size: cover;
    color: $white;
    min-height: 150px;
    border-radius: 0;
    border-top-right-radius: 8px;
    h3 {
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: 0.51px;
      text-align: left;
      color: #ffffff;
      margin-bottom: 5px;
    }
    h2 {
      font-size: 20px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: 0.73px;
      text-align: left;
      color: #ffffff;
      margin-bottom: 5px;
      padding: 0;
    }
    h4 {
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: 0.51px;
      text-align: left;
      color: #ffffff;
      margin-bottom: 5px;
    }

    .expDetail__date {
      float: right;
      background-color: #ffffff;
      color: $color1;
      padding: 1px 10px;
      border-radius: 20px;
    }
  }
  .expDetail__body {
    border-radius: 8px;
    box-shadow: 0 20px 35px -16px rgba(0, 0, 0, 0.07);
    background-color: #ffffff;
    padding: 25px;
    min-height: 478px;
    .expDetail__label {
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.67;
      letter-spacing: normal;
      text-align: left;
      color: #57525c;
      margin-bottom: 5px;
    }
    .expDetail__desc {
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.71;
      letter-spacing: normal;
      text-align: left;
      color: #000000;
      margin-bottom: 0;
      span {
        color: #4c4951;
      }
    }
    .expDetail__wrap {
      margin: 0 0 25px 0;
    }
  }
}
.skillTabList {
  position: relative;
  padding: 0 0 0 20px;
  margin: 0 0 25px 0;
  ul {
    height: 58px;
    overflow: hidden;
    outline: none;
  }
  .arrow__left {
    position: absolute;
    top: 5px;
    left: -15px;
    // border: solid 1px #dddae6;
    background-color: #ffffff;
    padding: 2px 0px 2px 8px;
    // border-top-right-radius: 15px;
    // border-bottom-right-radius: 15px;
    cursor: pointer;
  }
  .arrow__right {
    position: absolute;
    top: 5px;
    right: -15px;
    // border: solid 1px #dddae6;
    background-color: #ffffff;
    // border-top-left-radius: 15px;
    // border-bottom-left-radius: 15px;
    padding: 2px 8px 2px 0px;
    cursor: pointer;
  }
  li {
    list-style: none;
    display: inline-block;
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.75;
    letter-spacing: 0.6px;
    text-align: center;
    color: #4c4951;
    margin: 6px 25px 0 0;
    padding: 0 0 5px 0;
    border-bottom: 3px solid transparent;
    cursor: pointer;
    outline: none !important;
    &:hover {
      outline: none !important;
    }
    &:focus {
      outline: none !important;
    }
    &:active {
      outline: none !important;
    }
  }
  li.active {
    color: #6317f6;
    border-bottom: 3px solid #6317f6;
    outline: none;
  }
}
.highlights {
  position: relative;
  .highlights__slider {
    margin: 0;
    max-height: 344px;
    overflow: hidden;
  }
  .arrow__left {
    position: absolute;
    top: 170px;
    left: -12px;
    cursor: pointer;
  }
  .arrow__right {
    position: absolute;
    top: 170px;
    right: -12px;
    cursor: pointer;
  }
}
.skillbook {
  border-radius: 8px;
  background-color: #f1f1ff;
  margin-bottom: 25px;
  cursor: pointer;
  .skillbook__desigWrap {
    padding: 15px;
    border-bottom: 2px solid #e5e5ea;
    h2 {
      font-size: 20px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      text-align: left;
      color: #000000;
      margin: 0;
      padding: 0;
    }
    .skillbook__engagements {
      background-repeat: no-repeat;
      background-position: left 0;
      padding: 0 0 0 25px;
      margin: 10px 0 0 0;
    }
  }
  .skillbook__skillsWrap {
    padding: 15px;
    border-bottom: 2px solid #e5e5ea;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.67;
    letter-spacing: normal;
    text-align: left;
    color: #262626;
  }
  .skillbook__travelWrap {
    padding: 15px;
    border-bottom: 2px solid #e5e5ea;
    .online {
      float: left;
    }
    .travel {
      float: right;
      text-align: right;
    }
    p {
      margin: 0;
    }
    .noHire {
      font-size: 18px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.67;
      letter-spacing: normal;
      text-align: center;
      color: #000000;
    }
  }
  .skillbook__buttonWrap {
    padding: 15px;
    .skillbook__price {
      float: left;
      width: 40%;
      h3 {
        font-size: 18px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.3;
        letter-spacing: 0.25px;
        text-align: left;
        margin: 0;
      }
      p {
        color: #262626;
        margin: 0;
      }
    }
    .skillbook__button {
      float: right;
      width: 60%;
      text-align: right;
      button {
        font-size: 12px;
        width: auto;
      }
    }
    .skillbook__button--nohire {
      width: 100%;
      text-align: center;
    }
  }
  .tick {
    display: none;
  }
}

.highlight.skillbook {
  box-shadow: 0 39px 50px -50px rgba(98, 23, 246, 0.39);
  border-style: solid;
  border-width: 2px;
  border-image-source: linear-gradient(to top, #8259ff, #6217f6);
  border-image-slice: 0;
  background-image: linear-gradient(to bottom, #ffffff, #ffffff),
    linear-gradient(to top, #8259ff, #6217f6);
  background-origin: border-box;
  background-clip: content-box, border-box;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -o-border-radius: 8px;
  border-radius: 8px;
  .skillbook__buttonWrap {
    object-fit: contain;
    box-shadow: 0 50px 40px -50px rgba(98, 23, 246, 0.44);
    background-image: linear-gradient(to top, #8259ff, #6217f6);
    p,
    h3 {
      color: $white;
    }
    button {
      color: #6226f4;
      border: 1px solid $white;
    }
    .button--no-hire {
      color: #6226f4;
      border: 1px solid $white;
      width: 100%;
      text-align: center;
    }
  }
  .skillbook__desigWrap {
    position: relative;
    .tick {
      display: block;
      background-color: #00bec0;
      position: absolute;
      right: 0;
      top: 16px;
      background-repeat: no-repeat;
      font-size: 0;
      padding: 12px 20px 12px 10px;
      border-top-left-radius: 15px;
      border-bottom-left-radius: 15px;
      background-position: center center;
    }
  }
}

.professionCards {
  position: relative;
  right: 0px;
}
.audioCard {
  cursor: pointer;
  object-fit: contain;
  border-radius: 8px;
  box-shadow: 0 20px 35px -30px rgba(64, 57, 78, 0.15);
  background-color: #ffffff;
  padding: 20px;
  margin: 0 0 15px 0;
  &--noMargin {
    margin: 0;
  }
  .audioCard__thumb {
    width: 170px;
    float: left;
    max-height: 113px;
    position: relative;
    img {
      width: 170px;
      height: 113px;
      border-radius: 6px;
      object-fit: cover;
    }
    .audioThumb {
      width: 62px;
      height: 62px;
      position: absolute;
      top: 25px;
      left: 53px;
      z-index: 2;
    }
  }
  .audioCard__text {
    width: calc(100% - 170px);
    float: right;
    padding: 0 15px;
    background-repeat: no-repeat;
    background-position: right -14px;
    &--carousel {
      background-image: none;
    }

    h3 {
      font-size: 20px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: 0.5px;
      text-align: left;
      color: #000000;
      margin: 0 0 8px 0;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      width: 70%;
      max-height: 26px;
    }
    p {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.71;
      letter-spacing: normal;
      text-align: left;
      color: #4c4951;
      margin-bottom: 4px;
      span {
        font-weight: bold;
      }
    }
  }
}
.askpop {
  margin: 25px 0 0 0;
  .askpop__head {
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.08;
    letter-spacing: normal;
    text-align: left;
    color: #262626;
    margin: 0 0 25px 0;
  }
}
.MuiPopover-root {
  .MuiMenu-paper {
    max-height: 293px;
  }
}
.profileSpinnerContainer {
  height: 1000px;
}
.videoSlider {
  position: relative;
  .arrow__left {
    position: absolute;
    left: -15px;
    top: 436px;
    cursor: pointer;
  }
  .arrow__right {
    position: absolute;
    right: -15px;
    top: 436px;
    cursor: pointer;
  }
  .preview {
    position: relative;
    border-radius: 6px;
    padding: 0px 0 12px 0;
    img {
      width: 100%;
      height: 381.1px;
      object-fit: cover;
      border-radius: 6px;
      cursor: pointer;
      box-shadow: 0 20px 35px -16px rgba(64, 57, 78, 0.45);
    }
    .videoSlider__play {
      box-shadow:none;
      position: absolute;
      margin: 0 auto;
      width: 68px;
      height: 68px;
      left: 0;
      right: 0;
      top: 162.5px;
    }
  }
  .videoSlider__items__text {
    margin: 10px 22px 0 0;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: 0.51px;
    text-align: left;
    color: #000000;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    max-width: 165px;
    overflow: hidden;
    &--active {
      color: #6226f4;
    }
  }
}
.videoSlider__activity {
  position: relative;
  margin-top: 30px;
  .arrow__left {
    position: absolute;
    left: -15px;
    top: 540px;
    cursor: pointer;
  }
  .arrow__right {
    position: absolute;
    right: -15px;
    top: 540px;
    cursor: pointer;
  }
  .preview {
    position: relative;
    border-radius: 6px;
    padding: 0px 0 12px 0;
    .youtubeIframe {
      border-radius: 6px;
      margin-top: 69px;
      margin-bottom: -5px;
      box-shadow: 0 20px 35px 1px rgba(64, 57, 78, 0.25);
    }
    img {
      width: 674px;
      height: 379px;
      object-fit: cover;
      border-radius: 6px;
      cursor: pointer;
      box-shadow: 0 20px 35px 1px rgba(64, 57, 78, 0.25);
    }
    .videoSlider__play {
      box-shadow:none;
      position: absolute;
      width: 68px;
      height: 68px;
      left: 0;
      right: 0;
      top: 162.5px;
      margin: 0 auto;
    }
  }
  .videoSlider__items__text {
    margin: 10px 22px 0 0;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: 0.51px;
    text-align: left;
    color: #000000;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    max-width: 165px;
    overflow: hidden;
    min-height: 48px;
    &--active {
      color: #6226f4;
    }
  }
}
.videoGallery {
  margin-left: 0;
  .slick-slide {
    cursor: pointer;
    img {
      width: 180px;
      height: 100px;
      margin: 0 12px 10px 0;
      border-radius: 8px;
      object-fit: cover;
      box-shadow: 0 20px 35px -16px rgba(64, 57, 78, 0.15);
    }
  }
}
.videoGallery--activity {
  margin-left: 0;
  margin-top: 15px;
  margin-bottom: 40px;
  .slick-slide {
    cursor: pointer;
    img {
      width: 283px;
      height: 159px;
      margin: 0 20px 10px 0;
      border-radius: 8px;
      object-fit: cover;
      box-shadow: 0 20px 35px -16px rgba(64, 57, 78, 0.2);
    }
  }
}
.audioThumb {
  position: relative;
  text-align: center;
  cursor: pointer;
  .audioThumb__icons {
    position: absolute;
    margin: 0 auto;
    top: 20%;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .audioThumb__icon {
    margin-top: 0 !important;
  }
  .audioThumb__link {
    background-color: rgba(123, 129, 199, 0.7);
    font-size: 12px;
    color: #fff;
    padding: 0 25px;
    border-radius: 43px;
    margin-top: 9px;
  }
}
.videoPop__wrap {
  position: relative;
  cursor: pointer;
  .videoPop__icon {
    position: absolute;
    top: 20%;
    left: 0;
    right: 0;
    margin: 0 auto !important;
  }
}
.listingCard__peopleName {
  font-weight: 500;
}
.prof__result {
  font-size: 16px;
  margin: 0;
  padding: 5px 0 10px 0;
}
.ReactGridGallery img {
  border-radius: 8px !important;
}
.footer {
  background-image: linear-gradient(to bottom, #34005c, #590fec);
  padding: 44px 0;
  color: $white;
  z-index: 9999999;
  margin: 50px 0 0 0;
  .footer__appStore {
    margin-bottom: 11px;
    max-width: 111px;
  }
  .footer__playStore {
    max-width: 111px;
  }
  h5 {
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.63;
    letter-spacing: normal;
    text-align: left;
    color: #ffffff;
    margin: 0 0 4px 0;
  }
  ul {
    list-style: none;
  }
  .footer__links1 li,
  .footer__links2 li {
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.63;
    letter-spacing: normal;
    text-align: left;
    color: #ffffff;
    margin: 0 0 5px 0;
  }
  .footer__links2 {
    float: right;
    width: 50%;
  }
  .footer__links1 {
    float: left;
    width: 50%;
    max-width: 147px;
  }
  .footer__logo {
    max-width: 210px;
    margin-top: -25px;
  }
  .footer__logoWrap {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .footer__logoText {
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.86;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    margin: 8px 0 0 0;
  }
  .footer__wrap1 {
    border-bottom: 2px solid #ffffff;
    padding: 0 0 18px 0;
    margin: 0 0 18px 0;
  }
  .footer__wrap2 {
    p {
      font-size: 11px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.25;
      letter-spacing: normal;
      text-align: left;
      color: #ffffff;
      padding: 0;
      margin: 0 0 10px 0;
    }
    .footer__terms {
      li {
        font-size: 10px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 2.17;
        letter-spacing: normal;
        text-align: left;
        color: #ffffff;
        text-transform: uppercase;
        margin: 0 89px 0 0;
        float: left;
      }
    }
  }
}

.downloadApp {
  .popup__content.donloadApp__right.register__thanks {
    .downloadApp__alignText {
      h2 {
        margin: 0 !important;
      }
      p {
        padding: 0 0 15px 0;
        margin: 0;
      }
      button {
        width: 100%;
        max-width: 373px;
        border-radius: 6px;
      }
    }
  }
  .popup__content.donloadApp__right {
    text-align: center !important;
    .downloadApp__alignText {
      margin: 98px 0 0 0;
    }
    h2 {
      font-size: 24px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.08;
      letter-spacing: normal;
      text-align: center;
      color: #262626;
      margin: 0 0 38px 0 !important;
    }
    p {
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.57;
      letter-spacing: 0.51px;
      text-align: center;
      color: #000000;
      padding: 0;
      margin: 0 0 38px 0;
    }
    h5 {
      font-size: 12px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 2.17;
      letter-spacing: normal;
      text-align: center;
      color: #000000;
    }
  }
  .highlights__list {
    float: left;
    width: 50%;
    li {
      background-repeat: no-repeat;
      background-position: left;
      padding: 0 0 0 32px;
      margin: 0 0 10px 0;
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.67;
      letter-spacing: normal;
      text-align: left;
      color: #4c4951;
      list-style: none;
    }
  }
}
.cursorPointer {
  cursor: pointer;
}
.closeLocation {
  position: absolute;
  width: 14px;
  right: 10px;
  top: 5px;
  cursor: pointer;
}
.changeImg {
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 0.51px;
  color: #ffffff;
  position: absolute;
  bottom: 55px;
  left: 0;
  right: 0;
  text-align: center;
  text-transform: uppercase;
}
.highlights__slider {
  .slick-slide {
    width: 218px !important;
    margin-right: 10px;
    margin-bottom: 30px;
  }
}
.highlights__slider--carousel {
  .slick-slide {
    width: 218px !important;
    margin-right: 20px;
    margin-bottom: 30px;
  }
}
.audio__slider--carousel {
  .slick-slide {
    width: 500px !important;
    margin-right: 20px;
    margin-bottom: 40px;
  }
}
.announcemnts__slider {
  .slick-slide {
    margin-bottom: 20px;
    img{
      width: 100% !important;
      height: 342px !important;
      border-radius: 9px;
      object-fit: cover;
    }
  }
}
.paddingContainer {
  padding-top: 20px;
  padding-bottom: 339px;
}
.expDetail__secHead {
  background-color: #f1f1ff;
  padding: 5px 10px;
  margin: 0 0 15px 0;
  border-radius: 6px;
}
.askQus__success {
  text-align: center;
  margin-top: 75px;
  h2 {
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.08;
    letter-spacing: normal;
    text-align: center;
    color: #262626;
  }
  img {
    float: none !important;
    width: 177px !important;
    margin: 35px 0 25px 0;
  }
  p {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: 0.51px;
    text-align: center;
    color: #000000;
    span {
      text-decoration: underline;
      cursor: pointer;
    }
  }
}
.entry-footer .excerpt p {
  word-wrap: break-word;
}

.videoWithIframe {
  margin-top: -70px !important;
}
.searchDetail__wrap2 {
  margin-bottom: 197px;
}
.arrow__leftMar {
  margin-right: 8px;
}
.copyTooltip [class$="inner"] {
  background-color: transparent;
   p {
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.67;
    letter-spacing: normal;
    text-align: center;
    color: #4c4951;
  }
}
.copyTooltip [class$="arrow"] {
  display: none;
}

.copyTooltip--colleague [class$="inner"] {
  background-color: #fff;
  padding-top: 20px;
  opacity: 1;
  p {
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.67;
    letter-spacing: normal;
    text-align: center;
    color: #4c4951;
  }
}
.bs-tooltip-top .arrow::before {
  top: -0.2px;
  border-top-color: #fff;
}
.copyToast {
  width: 210px;
  position: absolute;
  top: 120px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  .copyToastHeader {
    padding: 6px 8px 6px 9px;
    border-radius: 4px;
    box-shadow: 0 7px 20px 0 rgba(0, 0, 0, 0.15);
    background-color: #ffffff;
    display: flex;
    flex-grow: 0;
    justify-content: center;
    align-content: center;
    p {
      margin: 6px 0px 6px 4px;
      flex-grow: 0;
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.67;
      letter-spacing: normal;
      text-align: center;
      color: #4c4951;
    }
  }
  .copyToastHeader--colleague {
    padding: 6px 8px 6px 9px;
    border-radius: 4px;
    box-shadow: 0 7px 20px 0 rgba(0, 0, 0, 0.15);
    background-color: #ffffff;
    display: flex;
    flex-grow: 0;
    justify-content: center;
    align-content: center;
    p {
      margin: 6px 0px 6px 4px;
      flex-grow: 0;
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.67;
      letter-spacing: normal;
      text-align: center;
      color: #4c4951;
    }
  }
}
.searchResult__wrapper {
  .tab-content {
    min-height: 750px;
    padding: 0 0 100px 0;
  }
}
.expDetail > div {
  border-radius: 8px;
}
.downloadApp.popup__wrap {
  border-radius: 9px !important;
}
.forgotPass__wrapper {
  background-repeat: no-repeat;
  background-position: right 86px;
  text-align: center;
  height: calc(100vh - 50px);
}
.forgotPass__container {
  width: 100%;
  max-width: 373px;
  margin: 47px auto 25px auto;
  label {
    text-align: left;
    width: 100%;
    float: left;
  }
  h2 {
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.08;
    letter-spacing: normal;
    color: #262626;
    padding: 0;
    margin: 0 0 14px 0;
    width: fit-content;
  }
  p {
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.67;
    letter-spacing: normal;
    text-align: left;
    color: #4c4951;
  }
  .resetPass__form {
    margin: 40px 0 0 0;
  }
  .verifyPass__form {
    margin: 20px 0 0 0;
    input {
      width: 48px;
      float: left;
      margin-right: 17px !important;
    }
    .verifyPass__inputLast {
      margin-right: 0px !important;
    }
    label {
      float: left;
      width: 100%;
    }
  }
  button {
    margin-top: 25px;
  }
}
.forgotPass__back {
  margin: 30px;
  float: left;
  width: 100%;
}
.forgotPassBtn {
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.17;
  letter-spacing: normal;
  color: #6226f4;
  text-align: right;
}
.resetPass__form {
  .password__wrap .inputIcon--password {
    top: 9px;
  }
}

.subHead__dot {
  width: 6px;
  height: 6px;
  margin: 13px 0 0 6px;
  background-color: #00bec0;
  border-radius: 6px;
  float: right;
}

.colleaguesPeopleCard {
  height: auto;
}
.colleagues__explain {
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
  text-align: left;
  color: #262626;
  margin-top: 25px;
}
.tab__body.colleagues__tabbody {
  .tab__body--left {
    float: left;
    width: 220px;
  }
  .nav {
    margin-top: 15px;
  }
  .nav-link {
    padding: 0 0 23px 0;
  }
  .tab__body--right {
    float: right;
    width: calc(100% - 220px);
    padding-left: 30px;
    border-left: 1px solid #aeaeae;
  }
  .professional__group {
    h2 {
      display: none;
    }
  }
  .nav-pills .nav-link.active {
    color: #6226f4;
  }
  .nav-pills .nav-link {
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 0.59px;
    text-align: left;
    color: #4c4951;
    background-color: transparent;
  }
}
.preview {
  width: 66%;
  float: left;
}
.browseVideo__desc {
  width: 34%;
  padding-left: 25px;
  float: right;
}
.browseVideoDescription {
  .browseVideoDescription__title {
    h3 {
      font-size: 18px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.33;
      letter-spacing: normal;
      text-align: left;
      color: #000000;
      max-width: 385px;
      width: 75%;
      float: left;
      margin: 0 0 20px 0;
      padding: 0;
    }
    .date__wrap {
      float: right;
      width: 25%;
      text-align: right;
      .date {
        background-repeat: no-repeat;
        padding-left: 22px;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.71;
        letter-spacing: normal;
        color: #4c4951;
        float: right;
        background-position: 0 2px;
      }
    }
  }
  .browseVideoDescription__body {
    padding: 0 0 15px 0;
    padding: 0;
    h5 {
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.67;
      letter-spacing: normal;
      text-align: left;
      color: #57525c;
      margin: 0;
      padding: 0;
    }
    p {
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.71;
      letter-spacing: normal;
      text-align: left;
      color: #000000;
    }
  }
}
.personDetailsCard {
  cursor: pointer;
  width: 100%;
  padding: 10px 10px 10px 0px;
  .personDetailsCard__icon {
    width: 56px;
    height: 56px;
    object-fit: cover;
    float: left;
    border-radius: 50%;
    img {
      width: 56px;
      height: 56px;
      object-fit: cover;
      border-radius: 50%;
    }
  }
  .personDetailsCard__body {
    width: calc(100% - 56px);
    float: right;
    padding: 6px 0 0 9px;
    h4 {
      font-size: 14px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: 0.51px;
      text-align: left;
      color: #000000;
      margin: 0;
      padding: 0 0 3px 0;
    }
    .personDetailsCard__designation {
      font-size: 12px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.67;
      letter-spacing: normal;
      text-align: left;
      color: #000000;
      padding: 0;
      margin: 0;
    }
    .personDetailsCard__date {
      font-size: 10px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 2;
      letter-spacing: normal;
      text-align: left;
      color: #4c4951;
      padding: 0;
      margin: 0;
    }
  }
}
.userColleagues {
  h3 {
    span {
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.71;
      letter-spacing: normal;
      text-align: left;
      color: #6226f4;
      padding-left: 15px;
      cursor: pointer;
    }
  }
  ul {
    margin: 0;
    padding: 0;
    li {
      cursor: pointer;
      width: 90px;
      text-align: center;
      list-style: none;
      border-radius: 50%;
      float: left;
      margin-right: 10px;
      object-fit: cover;
      img {
        width: 74px;
        height: 74px;
        max-width: 100%;
        flex-grow: 0;
        border-radius: 50%;
        object-fit: cover;
      }
      h3 {
        padding: 3px 0px 2px 0px;
        margin: 12px 0 15px 0;
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.67;
        letter-spacing: normal;
        text-align: center;
        color: #262626;
        width: 90px;
        white-space: nowrap;
        overflow: hidden !important;
        text-overflow: ellipsis;
      }
    }
  }
}

.profileVideo__wrap {
  .preview {
    width: 100%;
  }
}
.userColleagues__connections {
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: 0.51px;
  text-align: left;
  color: #000000;
  margin-top: 15px;
  span {
    text-decoration: underline;
  }
}
.withoutSearch {
.search__container {
  display: none;
}
}
.withoutSearch.header--search {
  padding: 28px 0 35px 0;
}
.header__backButton {
  display: none;
  cursor: pointer;
  width: 110px;
  float: left;
  font-weight: normal;
  img {
    width: 25px;
    padding: 0 0 3px 0;
    margin: 0 8px 0 0;
  }
}
.header__backButton.backButton--true {
  display: flex;
  width: 100%;
}
.popup__content {
  .tab-content {
    min-height: auto;
  }
}
.menu__wrapper {
  width: 40px;
  overflow: hidden;
}
.menu__wrapper.collapse {
  width: auto;
  overflow: visible;
}

.listingCard__activity.peopleListingCard {
  height: 175px;
}
@font-face {
    font-family: 'CircularStd-Bold';
    src: url('../../fonts/CircularStd-Bold.woff') format('woff'), url('../../fonts/CircularStd-Bold.ttf')  format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'CircularStd-Medium';
    src: url('../../fonts/CircularStd-Medium.woff') format('woff'), url('../../fonts/CircularStd-Medium.ttf')  format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'CircularStd-Book';
    src: url('../../fonts/CircularStd-Book.woff') format('woff'), url('../../fonts/CircularStd-Book.ttf')  format('truetype');
    font-weight: normal;
    font-style: normal;
  }